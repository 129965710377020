import { Box, Drawer, Hidden } from "@mui/material";
import { SidebarContext } from "../../contexts/SidebarContext";
import React from "react";
const ChatWindow = () => {
  const { chatboxToggle, toggleChatbox } = React.useContext(SidebarContext);
  const closeChatbox = () => toggleChatbox();

  return (
    <Drawer
      anchor="right"
      open={chatboxToggle}
      onClose={closeChatbox}
      variant="temporary"
      elevation={9}
      style={{
        zIndex: 5005,
      }}
    >
      <div style={{ width: "500px" }}></div>
    </Drawer>
  );
};
export default ChatWindow;
