import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Box,
  Typography,
  ListItemButton,
  ListItemText,
  List,
  TextField,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Theme,
  Stack,
  IconButton,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { loadVisuals } from "../../Redux/Visuals/VisualsSlice";
import VisualRenderer from "./VisualRenderer";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Scrollbars from "react-custom-scrollbars-2";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import {
  darkBgVariation,
  darkLightBgChange,
  darkLightTextChange,
} from "../../theme/muiGlobalCss";
import { ReactComponent as QuerBuilderIcon } from "../../Icons/QueryBuilder.svg";
import CustomScrollbars from "../../componets/CustomScrollbars/CustomScrollbars";
const useStyles = makeStyles((theme: Theme) => ({
  flexContainer: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  },
  titleContainer: {
    padding: "11px 10px 9px 8px",
    background: darkLightBgChange(theme),
    boxShadow:
      theme.palette.mode === "dark"
        ? "none"
        : "inset 0px -1px 0px rgba(159, 162, 191, 0.58)",
    borderRadius: theme.palette.mode === "dark" ? "5px" : "0",
  },
  titleText: {
    fontWeight: 700,
    fontSize: 13,
    color: darkLightTextChange(theme),
    display: "flex",
    alignItems: "center",
  },
  titleButton: {
    backgroundColor: "#0626C7",
    padding: "6.5px 13px",
    borderRadius: 0,
  },
  gridBackgroundWhite: {
    "&.MuiGrid-root": {
      background: theme.palette.mode === "dark" ? "#232A58" : "#EEEEEE",
    },
  },
  listItemText: {
    "&.MuiListItemText-multiline": {
      display: "flex",
    },
  },
  queryViewer: {
    marginTop: "5px",
    height: "100vh",
    background: darkLightBgChange(theme),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sidenav: {
    transition: "width 0.3s ease-in-out",
    height: "100vh",
  },
  sidenavClosed: {
    composes: "sidenav",
    transition: "width 0.3s ease-in-out",
    width: "60px",
  },
  closedMode: {
    height: "100%",
    writingMode: "vertical-rl",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    margin: " 0 10px",
    fontSize: "16px",
  },
}));

const QueryExecutionVisual = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const visualsList = useSelector((state: any) => state.visualsList);
  // React.useEffect(() => {
  //   dispatch(loadVisuals());
  // }, []);
  const [selectedVisualName, setSelectedVisualName] = React.useState("line");
  const [selectedVisual, setSelectedVisual] = React.useState({});

  React.useEffect(() => {
    setSelectedVisual(
      visualsList.data?.find((visual: any) => {
        return visual.visual_type === selectedVisualName;
      })
    );
  }, []);

  const tempQuery = props.customQuery;
  const query: any = {
    measures: ["FiveMinAgg.avgValue"],
    timeDimensions: [
      {
        dimension: "FiveMinAgg.timestamp",
        granularity: "minute",
        dateRange: "Last 7 days",
      },
    ],
    filters: [
      {
        member: "FiveMinAgg.tagName",
        operator: "equals",
        values: ["Blower1_States_scaled"],
      },
      {
        member: "Tags.deviceName",
        operator: "equals",
        values: ["Blower_V1_L1_1"],
      },
    ],
    limit: 5000,
  };
  let cubeName = "";
  if (
    props.customQuery &&
    props.customQuery.dimensions &&
    props.customQuery.dimensions.length > 0
  ) {
    cubeName = props.customQuery.dimensions[0].substr(
      0,
      props.customQuery.dimensions[0].indexOf(".")
    );
  } else if (
    props.customQuery &&
    props.customQuery.measures &&
    props.customQuery.measures.length > 0
  ) {
    cubeName = props.customQuery.measures[0].substr(
      0,
      props.customQuery.measures[0].indexOf(".")
    );
  }

  return (
    <Grid className={`${classes.gridBackgroundWhite}`}>
      <Box className={`${classes.titleContainer} ${classes.flexContainer}`}>
        <Typography gutterBottom variant="h5" className={classes.titleText}>
          <IconButton aria-label="delete" sx={{ color: "#b2bac2" }}>
            {/* {props.secondContainer ? (
              <KeyboardDoubleArrowLeftIcon />
            ) : (
              <KeyboardDoubleArrowRightIcon />
            )} */}
          </IconButton>
          {props.thirdContainer ? "Chart Type" : ""}
          <FormControl
            sx={{ margin: "0 10px 0 15px", minWidth: 120 }}
            size="small"
          >
            <Select
              value={selectedVisualName}
              onChange={(event) => {
                setSelectedVisualName(event.target.value);
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {visualsList.data
                ?.filter((visual: any) => {
                  return visual.available_in_query_builder === true;
                })
                .map((visual: any) => {
                  return (
                    <MenuItem value={visual.visual_type}>
                      {visual.visual_name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Typography>
      </Box>

      {props.thirdContainer ? (
        <Stack className={classes.queryViewer} borderTop="5px solid #293368">
          {props.customQuery === null ? (
            <QuerBuilderIcon />
          ) : (
            <CustomScrollbars>
              <VisualRenderer
                pivotConfig={props.pivotConfig}
                selectedVisual={visualsList.data?.find((visual: any) => {
                  return visual.visual_type === selectedVisualName;
                })}
                // granularity={props.customQuery?.timeDimensions[0].granularity}
                // cubeName={props.customQuery?.dimensions[0].substr(
                //   0,
                //   props.customQuery?.dimensions[0].indexOf(".")
                // )}
                query={props.customQuery}
              />
            </CustomScrollbars>
          )}
        </Stack>
      ) : (
        <Box className={classes.closedMode}>Chart</Box>
      )}
    </Grid>
  );
};

// QueryExecutionVisual.propTypes = {};

export default QueryExecutionVisual;
