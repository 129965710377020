import axios from "axios";
import { returnAccessToken } from "../authService";
import { env } from "../env";
//creating an axios instance that will be used throughout the app
const Axios = axios.create({
  baseURL: env.MONARCH_API_BASE_URL,
});

Axios.interceptors.request.use(
  async (config: any) => {
    const token = await returnAccessToken("API");
    config.headers.common["Authorization"] = "Bearer " + token;
    return config;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

//adding interceptor on response
Axios.interceptors.response.use(
  (response) => {
    // console.log("response: ", response);
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      throw new Error(`Your are UnAuthorized" , ${error.response.statusText}`);
    }
    return Promise.reject(error);
  }
);

export default Axios;
