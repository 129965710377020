import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ArchUsersState {
  loading: boolean;
  archUsers: object;
  archRoles: object;
  error: object;
  currentUser: object;
  curentUserPermissions: [];
  accesiblePlants: [];
}

const initialState: ArchUsersState = {
  loading: false,
  archUsers: [],
  archRoles: [],
  error: {},
  currentUser: {},
  curentUserPermissions: [],
  accesiblePlants: [],
};

export const ArchUsersSice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    // actions
    loadArchUsers: (state) => {
      return {
        ...state,
        loading: true,
        currentUser: {},
        curentUserPermissions: [],
        accesiblePlants: [],
      };
    },
    loadCurrentUser: (state: any, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
      };
    },
    loadCurrentUserSuccess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        currentUser: action.payload.currentUser,
        curentUserPermissions: action.payload.curentUserPermissions,
        accesiblePlants: action.payload.accessiblePlants,
      };
    },
    loadArchUsersSuceess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        archUsers: action.payload.archUsers,
        archRoles: action.payload.userPermissions,
        error: {},
      };
    },
    loadArchUsersError: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        archUsers: [],
        archRoles: [],
        error: action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadArchUsers,
  loadArchUsersSuceess,
  loadArchUsersError,
  loadCurrentUser,
  loadCurrentUserSuccess,
} = ArchUsersSice.actions;

export const ArchUsersReducer = ArchUsersSice.reducer;
