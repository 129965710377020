import axios from "../../config/axiosConfigArch";
import { takeLatest, put, call } from "redux-saga/effects";
import { loadModels, loadModelSuccess, loadModelError } from "./ModelSlice";

const getModelsData = async () => {
  let levels: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`model?pageSize=100&page=${page++}`);
    levels = levels.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);

  return levels;
};

function* listModelsData(): any {
  try {
    const data = yield call(() =>
      getModelsData().then((res) => {
        return res;
      })
    );

    yield put({
      type: loadModelSuccess.toString(),
      payload: data,
    });
  } catch (error) {
    yield put({
      type: loadModelError.toString(),
      payload: error,
    });
  }
}

export function* ModelSaga() {
  yield takeLatest(loadModels.toString(), listModelsData);
}
