import axios from "../../config/axiosConfig";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  loadSeed,
  loadSeedSuceess,
  loadSeedError,
  setCurrentSeedData,
  intialCurrentSeedData,
} from "./SeedSlice";

//function to get all usecases from the api
const getSeedData = async () => {
  const response = await axios.get("/seedconfigs");
  return response.data;
};

function* listSeedData(): any {
  try {
    const data = yield call(() =>
      getSeedData().then((res) => {
        return res;
      })
    );

    yield put({
      type: loadSeedSuceess.toString(),
      payload: data,
    });
  } catch (error) {
    yield put({
      type: loadSeedError.toString(),
      payload: error,
    });
  }
}

function* setSeedData(data: any): any {
  yield put({
    type: intialCurrentSeedData.toString(),
  });
  yield put({
    type: setCurrentSeedData.toString(),
    payload: data.action,
  });
}

export function* SeedSaga() {
  yield takeLatest(loadSeed.toString(), listSeedData);
  yield takeLatest("SET_SEED_DATA", setSeedData);
}
