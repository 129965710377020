import React, { useEffect } from "react";
import { useMsalAuthentication } from "@azure/msal-react";
import { loginRequest } from "./authService";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

function AuthAD(props: any) {
  useMsalAuthentication(InteractionType.Redirect, loginRequest);
  return <AuthenticatedTemplate>{props.children}</AuthenticatedTemplate>;
}

export default AuthAD;
