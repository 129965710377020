import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AliasState {
  loading: boolean;
  data: object;
  error: object;
}

const initialState: AliasState = {
  loading: false,
  data: [],
  error: {},
};

export const AliasSice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    // actions
    loadAlias: (state) => {
      return {
        ...state,
        loading: true,
        data: [],
        error: {},
      };
    },
    loadAliasSuceess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {},
      };
    },
    loadAliasError: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loadAlias, loadAliasSuceess, loadAliasError } =
  AliasSice.actions;

export const AliasReducer = AliasSice.reducer;
