import axios from "../../config/axiosConfigArch";
import { takeLatest, put, call } from "redux-saga/effects";
import { loadOEMs, loadOEMSuccess, loadOEMError } from "./OEMSlice";

const getOEMsData = async () => {
  let levels: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`oem?pageSize=100&page=${page++}`);
    levels = levels.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);

  return levels;
};

function* listOEMsData(): any {
  try {
    const data = yield call(() =>
      getOEMsData().then((res) => {
        return res;
      })
    );

    yield put({
      type: loadOEMSuccess.toString(),
      payload: data,
    });
  } catch (error) {
    yield put({
      type: loadOEMError.toString(),
      payload: error,
    });
  }
}

export function* OEMsSaga() {
  yield takeLatest(loadOEMs.toString(), listOEMsData);
}
