import axios from "../../config/axiosConfig";
import { takeLatest, put, call } from "redux-saga/effects";
import { loadAlias, loadAliasSuceess, loadAliasError } from "./AliasSlice";

//function to get all usecases from the api
const getAliasData = async () => {
  const response = await axios.get("/aliasviews");

  return response.data;
};

function* listAliasData(): any {
  try {
    const data = yield call(() =>
      getAliasData().then((res) => {
        return res;
      })
    );

    yield put({
      type: loadAliasSuceess.toString(),
      payload: data,
    });
  } catch (error) {
    yield put({
      type: loadAliasError.toString(),
      payload: error,
    });
  }
}

export function* AliasSaga() {
  yield takeLatest(loadAlias.toString(), listAliasData);
}
