import Scrollbars from "react-custom-scrollbars";
const CustomScrollbars = (props:any) => {
    let autoHeightMin = props.autoHeightMin || 0;
    let styles = props.style || {};
    let autoHeights:any = props.autoHeight ? true : false;
  return (
    <Scrollbars
      autoHide
      autoHeight={autoHeights}
      autoHeightMin={autoHeightMin}
      renderThumbVertical={({ style, ...props }: any) => (
        <div {...props} style={{ ...style, backgroundColor: "#434C78" }} />
      )}
      renderTrackHorizontal={({ style, ...props }: any) => (
        <div {...props} style={{ ...style, backgroundColor: "#434C78" }} />
      )}
      style={styles}
    >
      {props.children}  
    </Scrollbars>
  );
};

export default CustomScrollbars;
