import axios from "../../config/axiosConfigArch";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  loadMachines,
  loadMachinesSuceess,
  loadMachinesError,
} from "./MachinesSlice";

const getMachinesData = async () => {
  let levels: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`machines?pageSize=100&page=${page++}`);
    levels = levels.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);

  return levels;
};

function* listMachinesData(): any {
  try {
    const data = yield call(() =>
      getMachinesData().then((res) => {
        return res;
      })
    );

    yield put({
      type: loadMachinesSuceess.toString(),
      payload: data,
    });
  } catch (error) {
    yield put({
      type: loadMachinesError.toString(),
      payload: error,
    });
  }
}

export function* MachinesSaga() {
  yield takeLatest(loadMachines.toString(), listMachinesData);
}
