import React, { useState, useMemo } from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader,
  TextField,
  InputAdornment,
  Typography,
  Grid,
  IconButton,
  Button,
  Menu,
  Theme,
  Tooltip
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Chip from "@mui/material/Chip";
import FilterInput from "./FilterInput";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import Scrollbars from "react-custom-scrollbars";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const useStyles = makeStyles((theme: Theme) => ({
  clearIconStyle: {
    marginTop: "20px",
    "&.MuiChip-root": {
      background:
        theme.palette.mode === "dark" ? theme.colors.primary.main : "",
    },
  },
  helpText: {
    "&.css-6btap8-MuiButtonBase-root-MuiButton-root":{
      padding: "5px 5px 5px 10px",
      marginTop: "10px"
    }
  }
}));

const containsText = (optionObject: any, searchText: any) => {
  if (searchText && searchText.length > 1) {
    return (
      optionObject.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    );
  }
  return optionObject;
};

const FilterGroup = ({
  helpText,
  members,
  availableMembers,
  addMemberName,
  updateMethods,
  duplicateCheckAdd,
  duplicateCheckRemove,
}: any) => {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState("");

  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () =>
      availableMembers.filter((option: any) =>
        containsText(option, searchText)
      ),
    [searchText, availableMembers]
  );

  //Menu group
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment key="filterGroup">
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ListSubheader>
          <TextField
            size="medium"
            // Autofocus on textfield
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {displayedOptions.map((item: any, i: any) => (
          <MenuItem
            key={item.name}
            value={item.name}
            onClick={() => {
              if (duplicateCheckAdd(item.name, "filter")) {
                updateMethods.add({ dimension: item });
              }
              setSearchText("");
              handleClose();
            }}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
      {members.map((m: any, i: any) => {
        return (
          <Grid container key={m.member} spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={4}>
              <Chip
                className={classes.clearIconStyle}
                label={m.member}
                title={m.member}
                variant="filled"
                size="medium"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              {/* <Chip label={m.dimension.title} variant="outlined"
                                onDelete={() => updateMethods.remove(m)}
                                onClick={() => updateMethods.update(m, { dimension: m })} />  */}
              <FormControl fullWidth size="small">
                <Typography gutterBottom variant="h5">
                  Type
                </Typography>
                <Select size="medium" defaultValue="" value={m.operator}>
                  {m.operators.map((item: any) => {
                    return (
                      <MenuItem
                        key={item.name}
                        value={item.name}
                        onClick={() => {
                          let value = item.name;
                          updateMethods.update(m, { ...m, operator: value });
                        }}
                      >
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FilterInput
                member={m}
                key="filterInput"
                updateMethods={updateMethods}
                values={m.values}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <IconButton
                style={{ marginTop: "20px" }}
                onClick={() => {
                  duplicateCheckRemove(m.dimension.name, "filter");
                  updateMethods.remove(m);
                }}
              >
                <CloseIcon sx={{ color: "#FFFFFFB3" }} />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}
      <Button variant="outlined" onClick={handleClick} className={classes.helpText}>
        Add Filter
        <Tooltip title={helpText}>
          <IconButton  size="small">
              <InfoOutlinedIcon sx={{ color: "white", fontSize: "18px" }} />
          </IconButton>
         </Tooltip>
      </Button>
    </React.Fragment>
  );
};

export default FilterGroup;
