import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AlertState {
  type: string;
  message: string;
  open: boolean;
}

const initialState: AlertState = {
  type: "",
  message: "",
  open: false,
};

export const AlertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    // actions
    alertOpen: (state, action: PayloadAction<any>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      return {
        ...state,
        type: action.payload.type,
        message: action.payload.message,
        open: action.payload.open,
      };
    },
    alertClose: (state) => {
      return {
        ...state,
        type: "",
        message: "",
        open: false,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { alertOpen, alertClose, reset } = AlertSlice.actions;

export const AlertReducer = AlertSlice.reducer;
