import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { SidebarProvider } from "./contexts/SidebarContext";
import { msConfig } from "./authService";
import { MsalProvider } from "@azure/msal-react";
import { store } from "./Redux/index";
import { Provider } from "react-redux";
import AuthAD from "./Auth";
import { env } from "./env";
import * as devtool from "./devtools";

/**
 * Set it to true for enable debug
 */
// const DEBUG = env.environment === "QA";

// /**
//  * Debug configuration
//  */
// if (!DEBUG) {
//   devtool.disableConsoleLogs();
//   devtool.disableContextMenu();
//   devtool.disableHotKeys();
//   devtool.legalDevtoolWarning();
//   // devtool.disable40XErrorConsoleLogs();
// }

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HashRouter>
      <Provider store={store}>
        <SidebarProvider>
          <MsalProvider instance={msConfig}>
            <AuthAD>
              <App />
            </AuthAD>
          </MsalProvider>
        </SidebarProvider>
      </Provider>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
