import axios from "../../config/axiosConfigArch";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  loadAssetTypes,
  loadAssetTypesSuceess,
  loadAssetTypesError,
} from "./AssetTypesSlice";

const getAssetTypesData = async () => {
  let levels: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`assettypes?pageSize=100&page=${page++}`);
    levels = levels.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);

  return levels.sort((a: any, b: any) =>
    a.asset_type_name.toLowerCase() > b.asset_type_name.toLowerCase()
      ? 1
      : b.asset_type_name.toLowerCase() > a.asset_type_name.toLowerCase()
      ? -1
      : 0
  );
};

function* listAssetTypesData(): any {
  try {
    const data = yield call(() =>
      getAssetTypesData().then((res) => {
        return res;
      })
    );

    yield put({
      type: loadAssetTypesSuceess.toString(),
      payload: data,
    });
  } catch (error) {
    yield put({
      type: loadAssetTypesError.toString(),
      payload: error,
    });
  }
}

export function* AssetTypesSaga() {
  yield takeLatest(loadAssetTypes.toString(), listAssetTypesData);
}
