import axios from "../../config/axiosConfigArch";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  loadSensors,
  loadSensorsSuceess,
  loadSensorsError,
} from "./SensorsSlice";

const getSensorsData = async () => {
  let levels: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`sensors?pageSize=100&page=${page++}`);
    levels = levels.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);

  return levels;
};

function* listSensorsData(): any {
  try {
    const data = yield call(() =>
      getSensorsData().then((res) => {
        return res;
      })
    );

    yield put({
      type: loadSensorsSuceess.toString(),
      payload: data,
    });
  } catch (error) {
    yield put({
      type: loadSensorsError.toString(),
      payload: error,
    });
  }
}

export function* SensorsSaga() {
  yield takeLatest(loadSensors.toString(), listSensorsData);
}
