import { put, takeEvery, call, takeLatest } from "redux-saga/effects";
import { setCurrentUserPreference } from "./UsersSlice";
import { returnAccessToken } from "../../authService";
import { SetCurrentUserImage } from "../../utils/helper-functions/currentUserImage";
import {
  loadCurrentUserImage,
  loadCurrentUserError,
  loadCurrentUserSuccess,
} from "./UsersSlice";

function* setCurrentUser(action: any) {
  yield put({
    type: setCurrentUserPreference.toString(),
    payload: action.payload,
  });
}

function* storeUsersImage(action: any): any {
  try {
    const acessToken = yield call(() =>
      returnAccessToken().then((res) => {
        return res;
      })
    );

    if (acessToken) {
      const userImage = yield call(() =>
        SetCurrentUserImage(acessToken).then((res) => {
          return res;
        })
      );

      yield put({
        type: loadCurrentUserSuccess.toString(),
        payload: userImage,
      });
    }
  } catch (error) {
    // console.error(error)
    yield put({
      type: loadCurrentUserError.toString(),
      payload: error,
    });
  }
}

export function* UsersSaga() {
  yield takeEvery("SET_USER_PREFERENCE", setCurrentUser);
  yield takeLatest(loadCurrentUserImage.toString(), storeUsersImage);
}
