import { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Hidden,
  List,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { ReactComponent as AccountSettings } from "./AccoutSettings.svg";
import { ReactComponent as Signout } from "./signout.svg";
import { ListItemButton, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles((theme) => ({
  imageBorder: {
    borderRadius: "10%",
  },
  imageBorderOne: {
    borderRadius: "50%",
  },
  nav: {
    "&.MuiList-root.MuiList-root": {
      background: "#2F3B79",
      padding: "10px 5px",
    },
  },
  text: {
    "& .MuiListItemText-primary": {
      color: "#D9D9D9",
      fontSize: 14,
      width: "100px",
    },
  },
  divider: {
    "&.MuiBox-root": {
      borderBottom: "solid #D9D9D9",
      borderBottomWidth: "thin",
      margin: "8px 5px 0 5px",
    },
  },
  listButton: {
    "&.MuiListItemButton-root": {
      // marginBottom: "6px",
      "&:hover": {
        backgroundColor: "#8476EF",
        color: "white",
      },
    },
  },
}));

function HeaderUserbox() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accounts, instance } = useMsal();

  const user = {
    name: accounts[0]?.name,
    avatar: "",
    mailId: accounts[0]?.username,
    jobtitle: "",
  };

  // const user = {
  //   name: data.account.name,
  //   avatar: "/static/images/avatars/1.jpg",
  //   jobtitle:
  //     data.account.idToken.roles === undefined
  //       ? ""
  //       : data.account.idToken.roles[0],
  // };

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const logout = () => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  const usersList = useSelector((state: any) => state.usersList);

  return (
    <>
      <Button color="secondary" ref={ref} onClick={handleOpen}>
        {usersList?.currentUserImage === undefined ||
        usersList?.currentUserImage === null ? (
          <Avatar
            variant="rounded"
            alt={user.name}
            src={user.avatar}
            sx={{ background: "rgb(132, 118, 239)" }}
          />
        ) : (
          <img
            src={usersList?.currentUserImage}
            className={`kt-widget__img ${classes.imageBorder}`}
            height="50px"
            alt="user-logo"
          />
        )}

        <Hidden mdDown>
          <Box>
            <Typography
              variant="body1"
              style={{
                color: "white",
                fontWeight: 600,
                marginLeft: "8px",
                whiteSpace: "nowrap",
              }}
            >
              {user.name}
            </Typography>
            {/* <UserBoxDescription variant="body2">
                    {user.jobtitle}
                  </UserBoxDescription> */}
          </Box>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} style={{ color: "white" }} />
        </Hidden>
      </Button>

      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        style={{ zIndex: "1305" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {/* <MenuUserBox sx={{ minWidth: 210 }}> */}
        {/* {usersList.currentUser?.userImage === undefined ||
                usersList.currentUser?.userImage === null ? (
                  <Avatar variant="rounded" alt={user.name} src={user.avatar} />
                ) : (
                  <img
                    src={usersList.currentUser?.userImage}
                    className={`kt-widget__img ${classes.imageBorderOne}`}
                    height="75px"
                    alt="user-logo"
                  />
                )} */}
        {/* <UserBoxText> */}
        {/* <UserBoxLabel variant="body1">{user.name}</UserBoxLabel> */}
        {/* <UserBoxDescription variant="body2">
                    {user.jobtitle}
                  </UserBoxDescription> */}
        {/* </UserBoxText> */}

        {/* <RolesList>
                  <Typography
                    gutterBottom
                    sx={{ fontSize: "13px", color: "#6FCF97" }}
                    component="h5"
                  >
                    Roles
                  </Typography>
                  {usersList.currentUserPermissions?.map((data: any) => {
                    return (
                      <Typography gutterBottom sx={{ color: "#D9D9D9" }}>
                        {data}
                      </Typography>
                    );
                  })}
                </RolesList> */}

        {/* <Box className={classes.divider}></Box> */}
        {/* </MenuUserBox> */}

        <List component="nav" className={classes.nav}>
          {/* <ListItem button to="/user/profile/details" component={NavLink}>
                  <AccountBoxTwoToneIcon fontSize="small" />
                  <ListItemText primary="My Profile" />
                </ListItem>
                <ListItem button to="/user/messenger" component={NavLink}>
                  <InboxTwoToneIcon fontSize="small" />
                  <ListItemText primary="Messenger" />
                </ListItem>
                <ListItem
                  button
                  to="/user/profile/settings"
                  component={NavLink}
                >
                  <AccountTreeTwoToneIcon fontSize="small" />
                  <ListItemText primary="Account Settings" />
                </ListItem> */}

          <ListItemButton
            component="button"
            onClick={logout}
            className={classes.listButton}
          >
            <Signout style={{ marginRight: "10px" }} />
            <ListItemText primary="Sign out" className={classes.text} />
          </ListItemButton>
        </List>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
