import axios from "../../config/axiosConfigArch";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  loadArchUsers,
  loadArchUsersSuceess,
  loadArchUsersError,
  loadCurrentUser,
  loadCurrentUserSuccess,
} from "./ARCHUsersSlice";

const getArchUsersData = async () => {
  let archUsers: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`users?pageSize=100&page=${page++}`);
    archUsers = archUsers.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);

  const userPermissions = await axios.get(
    "userPermissions?pageSize=100&page=1"
  );

  return {
    archUsers: archUsers,
    userPermissions: userPermissions.data.rows,
  };
};

function* listArchUsersData(): any {
  try {
    const data = yield call(() =>
      getArchUsersData().then((res) => {
        return res;
      })
    );
    yield put({
      type: loadArchUsersSuceess.toString(),
      payload: data,
    });
  } catch (error) {
    yield put({
      type: loadArchUsersError.toString(),
      payload: error,
    });
  }
}

function* loadCurrentUserData(data: any): any {
  yield put({
    type: loadCurrentUserSuccess.toString(),
    payload: {
      currentUser: data.payload.currentUser,
      curentUserPermissions: data.payload.curentUserPermissions,
      accessiblePlants: data.payload.accessiblePlants,
    },
  });
}

export function* ArchUsersSaga() {
  yield takeLatest(loadArchUsers.toString(), listArchUsersData);
  yield takeLatest(loadCurrentUser.toString(), loadCurrentUserData);
}
