import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SensorsState {
  loading: boolean;
  data: object;
  error: object;
}

const initialState: SensorsState = {
  loading: false,
  data: [],
  error: {},
};

export const SensorsSice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    // actions
    loadSensors: (state) => {
      return {
        ...state,
        loading: true,
        data: [],
        error: {},
      };
    },
    loadSensorsSuceess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {},
      };
    },
    loadSensorsError: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loadSensors, loadSensorsSuceess, loadSensorsError } =
  SensorsSice.actions;

export const SensorsReducer = SensorsSice.reducer;
