import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ModelState {
  loading: boolean;
  data: object;
  error: object;
}

const initialState: ModelState = {
  loading: false,
  data: [],
  error: {},
};

export const ModelSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    // actions
    loadModels: (state) => {
      return {
        ...state,
        loading: true,
        data: [],
        error: {},
      };
    },
    loadModelSuccess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {},
      };
    },
    loadModelError: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loadModels, loadModelSuccess, loadModelError } = ModelSlice.actions;

export const ModelReducer = ModelSlice.reducer;
