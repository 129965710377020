import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface KPIState {
  loading: boolean;
  data: object;
  error: object;
}

const initialState: KPIState = {
  loading: false,
  data: [],
  error: {},
};

export const KPISice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    // actions
    loadKPI: (state) => {
      return {
        ...state,
        loading: true,
        data: [],
        error: {},
      };
    },
    loadKPISuceess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {},
      };
    },
    loadKPIError: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loadKPI, loadKPISuceess, loadKPIError } = KPISice.actions;

export const KPIReducer = KPISice.reducer;
