import axios from "../../config/axiosConfig";
import { takeLatest, put, call } from "redux-saga/effects";
import { loadKPI, loadKPISuceess, loadKPIError } from "./KPISlice";

//function to get all usecases from the api
const getKPIData = async () => {
  const response = await axios.get("/kpis");

  return response.data;
};

function* listKPIData(): any {
  try {
    const data = yield call(() =>
      getKPIData().then((res) => {
        return res;
      })
    );

    yield put({
      type: loadKPISuceess.toString(),
      payload: data,
    });
  } catch (error) {
    yield put({
      type: loadKPIError.toString(),
      payload: error,
    });
  }
}

export function* KPISaga() {
  yield takeLatest(loadKPI.toString(), listKPIData);
}
