import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DevicesState {
  loading: boolean;
  data: object;
  error: object;
}

const initialState: DevicesState = {
  loading: false,
  data: [],
  error: {},
};

export const DevicesSice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    // actions
    loadDevices: (state) => {
      return {
        ...state,
        loading: true,
        data: [],
        error: {},
      };
    },
    loadDevicesSuceess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {},
      };
    },
    loadDevicesError: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loadDevices, loadDevicesSuceess, loadDevicesError } =
  DevicesSice.actions;

export const DevicesReducer = DevicesSice.reducer;
