import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PlantsState {
  loading: boolean;
  data: object;
  error: object;
}

const initialState: PlantsState = {
  loading: false,
  data: [],
  error: {},
};

export const PlantsSice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    // actions
    loadPlants: (state) => {
      return {
        ...state,
        loading: true,
        data: [],
        error: {},
      };
    },
    loadPlantsSuceess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {},
      };
    },
    loadPlantsError: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loadPlants, loadPlantsSuceess, loadPlantsError } =
  PlantsSice.actions;

export const PlantsReducer = PlantsSice.reducer;
