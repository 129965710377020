import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AssetsState {
  loading: boolean;
  data: object;
  error: object;
}

const initialState: AssetsState = {
  loading: false,
  data: [],
  error: {},
};

export const AssetsSice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    // actions
    loadAssets: (state) => {
      return {
        ...state,
        loading: true,
        data: [],
        error: {},
      };
    },
    loadAssetsSuceess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {},
      };
    },
    loadAssetsError: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loadAssets, loadAssetsSuceess, loadAssetsError } =
  AssetsSice.actions;

export const AssetsReducer = AssetsSice.reducer;
