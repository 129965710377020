import { takeLatest, put } from "redux-saga/effects";
import { alertOpen } from "./AlertSlice";

function* loadSnackBarSaga(action: any) {
  yield put({
    type: alertOpen.toString(),
    payload: action.payload,
  });
}

export function* AlertSaga() {
  yield takeLatest("LOAD_SNACKBAR", loadSnackBarSaga);
}
