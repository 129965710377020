import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Box,
  Typography,
  ListItemButton,
  List,
  ListItemText,
  TextField,
  Theme,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Autocomplete,
  Button,
  IconButton,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Switch,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { makeStyles } from "@mui/styles";
import { QueryBuilder } from "@cubejs-client/react";
import { ReactComponent as TickIcon } from "../../Icons/TickIcon.svg";
import Loader from "../../componets/Loader";
import {
  darkBgVariation,
  darkBgVariation2,
  darkLightBgChange,
  darkLightTextChange,
} from "../../theme/muiGlobalCss";
import Scrollbars from "react-custom-scrollbars-2";
import { useDispatch } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CustomScrollbars from "../../componets/CustomScrollbars/CustomScrollbars";
import { AppContext } from "../../App";
const ListItemWrapper = styled(ListItemButton)(
  ({ theme }) => `
      &.MuiButtonBase-root {
          margin: ${theme.spacing(1)} 0;
          border: ${
            theme.palette.mode === "dark" ? "none" : "1px solid #E5E8F2"
          };
          border-radius: 4px;
          padding: 20px 10px;
          height:41px;
          color: ${darkLightTextChange(theme)};
          background: rgb(41, 51, 104);
      }
      `
);

const ListContainer = styled(List)(
  ({ theme }) => `
      &.MuiList-root {
          margin:0;
          padding: 0px 6px;
          height: 100%;
          background: ${darkLightBgChange(theme)};
      }
      `
);

const useStyles = makeStyles((theme: Theme) => ({
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleContainer: {
    background: darkLightBgChange(theme),
    boxShadow:
      theme.palette.mode === "dark"
        ? "none"
        : "inset 0px -1px 0px rgba(159, 162, 191, 0.58)",
    borderRadius: theme.palette.mode === "dark" ? "5px" : "0",
    padding: "12px",
  },
  titleContainer1: {
    background: darkLightBgChange(theme),
    boxShadow:
      theme.palette.mode === "dark"
        ? "none"
        : "inset 0px -1px 0px rgba(159, 162, 191, 0.58)",
    borderRadius: theme.palette.mode === "dark" ? "5px" : "0",
    padding: 0,
  },
  iconButton: {
    "&.MuiButtonBase-root": {
      paddingLeft: "0px",
    },
  },
  iconButton1: {
    "&.MuiButtonBase-root": {
      marginTop: "12px",
    },
  },
  titleText: {
    fontWeight: 700,
    fontSize: 13,
    color: darkLightTextChange(theme),
  },
  boxBackgroundWhite: {
    "&.MuiBox-root": {
      height: "100%",
      background: darkLightBgChange(theme),
    },
  },
  input: {
    background:
      theme.palette.mode === "dark" ? theme.colors.primary.main : "#EEEEEE",
    color: darkLightTextChange(theme),
  },
  activeCategory: {
    "&.MuiListItemButton-root.Mui-selected": {
      background:
        theme.palette.mode === "dark" ? theme.colors.secondary.main : "#0626C7",
      color: "#FFF",
      "&:hover": {
        background:
          theme.palette.mode === "dark"
            ? theme.colors.secondary.main
            : "#0626C7",
      },
    },
  },
  closedMode: {
    height: "100%",
    width: "100%",
    writingMode: "vertical-rl",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    // margin: " 0 10px",
    fontSize: "16px",
  },
  checkBoxLabel: {
    "&.MuiFormControlLabel-root .MuiTypography-root": {
      fontSize: "14px",
    },
  },
  switchStyles: {
    "& .MuiSwitch-track": {
      background: "#FFF",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      background: "#FFF",
    },
    "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
      background: "#FFF",
    },
  },
}));

const QueryList = ({
  members,
  availableMembers,
  addMemberName,
  updateMethods,
  title,
  duplicateCheckAdd,
  cubeMeta,
  setSelectedCube,
  selectedCube,
  resetQuery,
  updateQuery,
  setResetFlag,
  firstContainer,
  setFirstContainer,
  helpText,
  fetchCubeAccessToken,
}: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const appContextProps: any = React.useContext(AppContext);
  const [fetchUseCaseCube, setFetchUseCaseCube] =
    React.useState<boolean>(false);

  const toggleOpenFirst = () => {
    dispatch({
      type: "SET_COLLAPSIBLE_DATA",
      payload: {
        first: !firstContainer,
      },
    });
    setFirstContainer(!firstContainer);
  };

  return (
    <Box className={`${classes.boxBackgroundWhite}`}>
      <Box
        className={`${
          firstContainer ? classes.titleContainer : classes.titleContainer1
        }`}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography gutterBottom variant="h5" className={classes.titleText}>
            <IconButton
              aria-label="delete"
              onClick={toggleOpenFirst}
              sx={{ color: "#b2bac2" }}
              className={
                firstContainer ? classes.iconButton : classes.iconButton1
              }
            >
              {firstContainer ? (
                <KeyboardDoubleArrowLeftIcon />
              ) : (
                <KeyboardDoubleArrowRightIcon />
              )}
            </IconButton>
            {firstContainer ? "Measures" : ""}
            {firstContainer ? (
              <Tooltip title={helpText}>
                <IconButton>
                  <InfoOutlinedIcon sx={{ color: "white" }} />
                </IconButton>
              </Tooltip>
            ) : (
              ""
            )}
          </Typography>
          {firstContainer ? (
            <FormGroup>
              <FormControlLabel
                className={classes.checkBoxLabel}
                control={
                  <Switch
                    className={classes.switchStyles}
                    checked={fetchUseCaseCube}
                    onChange={() => {
                      if (!fetchUseCaseCube) {
                        fetchCubeAccessToken("INJECTION");
                      } else {
                        fetchCubeAccessToken(
                          appContextProps.selectedPlantForCube
                        );
                      }
                      setFetchUseCaseCube(!fetchUseCaseCube);
                    }}
                    name="usecase switch"
                  />
                }
                label="Fetch usecase cube"
              />
            </FormGroup>
          ) : null}
        </Stack>
      </Box>

      {firstContainer ? (
        <ListContainer>
          <Autocomplete
            options={cubeMeta && cubeMeta.cubes ? cubeMeta.cubes : []}
            getOptionLabel={(option: any) => option.title}
            onChange={(event: any, newValue: string | null) => {
              setSelectedCube(newValue);
              let emptyQuery = resetQuery();
              updateQuery(emptyQuery);
              setResetFlag((prevState: boolean) => !prevState);
            }}
            value={selectedCube}
            renderInput={(params: any) => (
              <TextField {...params} placeholder="Select Cube" />
            )}
          />
          <CustomScrollbars>
            {availableMembers.length === 0 ? (
              <React.Fragment key="1"></React.Fragment>
            ) : (
              availableMembers.map((data: any) => {
                return (
                  <ListItemWrapper
                    key={data.name}
                    className={classes.activeCategory}
                    selected={false}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "h6",
                      }}
                      primary={data.shortTitle}
                      onClick={() => {
                        if (duplicateCheckAdd(data.name, "dimension")) {
                          updateMethods.add(data);
                        }
                      }}
                    />
                    {members.map((item: any, i: any) => {
                      if (item.name === data.name) {
                        return <TickIcon key={i} />;
                      }
                    })}
                  </ListItemWrapper>
                );
              })
            )}
          </CustomScrollbars>
        </ListContainer>
      ) : (
        <Box className={classes.closedMode}>Measure</Box>
      )}
    </Box>
  );
};

// QueryList.propTypes = {};

export default QueryList;
