import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Typography, Grid, Button } from "@mui/material";
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    marginBottom: "20px",
  },
  iconContainer: {
    textAlign: "center",
    "& .MuiSvgIcon-root": {
      fontSize: "30px",
    },
  },
  infoContainer: {
    color: "white",
    borderRadius: "3px",
    marginTop: "-5px"
  },
  button: {
    "& .MuiButton-root": {
      backgroundColor: "#293368",
      color: "white",
      marginTop: "30px",
      width: "95%",
      fontWeight: "500",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#8476EF",
        border: "1px solid #000"
      },
      border: "1px solid #fff"
    },
  },
  containedButton: {
    "& .MuiButton-root": {
      backgroundColor: "#8476EF",
      color: "white",
      marginTop: "30px",
      width: "95%",
      fontWeight: "500",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#8476EF",
        border: "1px solid #000"
      },
    },
  },
}));

function Alert(props: any) {
  const classes = useStyles();
  const appMode = localStorage.getItem("appMode");
  
  let time = props.time;
  let hours = Math.floor(time / 3600);
  let minutes = Math.floor(time/60);
  let seconds:number = time % 60;
  seconds = seconds<10?Number('0'+seconds):seconds;

  let hourText = hours > 0 ? minutes + " hr" : "";
  let minuteText = minutes > 0 ? minutes + " min" : "";
  let secondText = seconds > 0 ? seconds + " sec" : "";

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 470,
    boxShadow: "8px 24px 91px -11px rgba(12, 18, 52, 0.3)",
    p: "16px 40px",
    color: "white",
    borderRadius: "12px",
    bgcolor: "#293368",
    border: "#293368",
    ":focus": { outline: "none" },
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={(e: any, reason: string) => {
        if (reason !== "backdropClick") {
          props.handleClose();
        } else {
          e.stopPropagation();
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onClick={e => e.stopPropagation()}
    >
      <Fade in={props.open}>
        <Box sx={style}>
          <Grid
            className={classes.root}
            container
            direction="row"
            spacing={3}
          >
            <Grid
              className={classes.iconContainer}
              style={{
                marginTop: "20px",
              }}
              item
              xs={12}
            >
              <HourglassDisabledIcon sx={{ color: "#8476EF" }} />
            </Grid>

            <Grid item xs={12} style={{ paddingTop: "15px" }}>
              <Typography sx={{ fontSize: "1.2rem" }}>
                Session Timeout
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.infoContainer}>
                <Typography
                  align="center"
                  style={{
                    fontWeight: "300",
                    fontSize: "12px",
                    color: appMode === "Dark" ? "text.secondary" : "black",
                  }}
                >
                  Your online session will expire in
                </Typography>
                <Typography
                  align="center"
                  style={{
                    fontWeight: "400",
                    fontSize: "20px",
                    marginTop: "15px",
                    color: appMode === "Dark" ? "text.secondary" : "black",
                  }}
                >
                  {hourText+ " " +minuteText + " " + secondText}
                </Typography>
                <Typography
                  align="center"
                  style={{
                    fontWeight: "300",
                    fontSize: "12px",
                    marginTop: "15px",
                    color: appMode === "Dark" ? "text.secondary" : "black",
                  }}
                >
                  Please click “continue” to keep working or click “log off” to end your session now
                </Typography>
              </div>
            </Grid>
            <Grid
              style={{ paddingTop: "0px" }}
              item
              xs={6}
              className={classes.containedButton}
            >
              <Button
                onClick={() => {
                  props.handleContinue();
                }}
              >
                Continue
              </Button>
            </Grid>
            <Grid
              style={{ paddingTop: "0px" }}
              item
              xs={6}
              className={classes.button}
            >
              <Button
                onClick={() => {
                  props.handleClose();
                }}
              >
                Log Off
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}

export default Alert;
