import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface OEMsState {
  loading: boolean;
  data: object;
  error: object;
}

const initialState: OEMsState = {
  loading: false,
  data: [],
  error: {},
};

export const OEMSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    // actions
    loadOEMs: (state) => {
      return {
        ...state,
        loading: true,
        data: [],
        error: {},
      };
    },
    loadOEMSuccess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {},
      };
    },
    loadOEMError: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loadOEMs, loadOEMSuccess, loadOEMError } = OEMSlice.actions;

export const OEMsReducer = OEMSlice.reducer;
