import { takeLatest, put } from "redux-saga/effects";
import { updateCollapsible } from "./QuerySlice";

function* setCollapsibleData(data: any): any {
  yield put({
    type: updateCollapsible.toString(),
    payload: data.payload,
  });
}

export function* QuerySaga() {
  yield takeLatest("SET_COLLAPSIBLE_DATA", setCollapsibleData);
}
