import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { themeCreator } from "./base";
import { StylesProvider } from "@mui/styles";
import { useSelector } from "react-redux";

export const ThemeContext = React.createContext(
  (themeName: string): void => {}
);

const ThemeProviderWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const usersList = useSelector((state: any) => state.usersList);
  const userPreference = usersList?.currentUser?.themePreference;

  // const currThemeName =
  //   userPreference === "light" ? "PureLightTheme" : "PureDarkTheme";
  const currThemeName = "PureDarkTheme";
  const [themeName, _setThemeName] = useState(currThemeName);
  const theme = themeCreator(themeName);

  const setThemeName = (themeName: string): void => {
    localStorage.setItem("appTheme", themeName);
    localStorage.setItem(
      "appMode",
      themeName === "PureDarkTheme" ? "Dark" : "Light"
    );
    _setThemeName(themeName);
  };

  useEffect(() => {
    setThemeName(currThemeName);
  }, [currThemeName]);

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
