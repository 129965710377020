import * as React from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import {
    TextField,
    Badge,
    Tooltip,
    IconButton,
    Theme
} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme: Theme) => ({
    helpText: {
      "&.css-6btap8-MuiButtonBase-root-MuiButton-root":{
        padding: "5px 5px 5px 10px"
      }
    }
  }));
export default function SeetingLimit({ updatePivotConfig, resetFlag, limit, setLimit, helpText }: any) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [invisible, setInvisible] = React.useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const [limitText, setLimitText] = React.useState('');

    // React.useEffect(() => {
    //     //setInvisible(true);
    //     updatePivotConfig.update({ limit: 500 });
    //     setLimitText("");
    //     setLimit(500);
    // }, [resetFlag])
    return (
        <>
            <Badge color="primary" badgeContent={limit} invisible={invisible} max={99999}>
                <Button variant="outlined" onClick={handleClick} className={classes.helpText}>
                    Limit
                    <Tooltip title={helpText}>
                        <IconButton  size="small">
                            <InfoOutlinedIcon sx={{ color: "white", fontSize: "18px", marginLeft: "5px" }} />
                        </IconButton>
                    </Tooltip>
                </Button>
            </Badge>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Badge color="secondary" variant="dot" invisible={false}>
                    <TextField
                        sx={{ p: 1, width: '90px' }}
                        autoFocus
                        type="number"
                        variant="outlined"
                        size="small"
                        placeholder="Limit"
                        value={limitText}
                        onChange={(e: any) => {
                            let textValue = e.target.value;
                            setLimitText(textValue);
                            
                            if (textValue) {
                                updatePivotConfig.update({ limit: textValue });
                                //setInvisible(prevState => false);
                                setLimit(textValue);
                            }else{
                                updatePivotConfig.update({ limit: 500 });
                                //setInvisible(prevState => true);
                                setLimit(500);
                            }
                        }}
                        inputProps={{ min: "1", max: "50000", step: "500" }}
                        onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                                e.stopPropagation();
                            }
                        }}
                    />
                </Badge>
            </Popover>
        </>
    );
}