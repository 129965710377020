import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import { SidebarContext } from "../../contexts/SidebarContext";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import HeaderActions from "./Actions";
import Logo from "./../Logo";
import { useDispatch, useSelector } from "react-redux";
import Userbox from "../Sidebar/Userbox";
import { makeStyles } from "@mui/styles";
import LogoMonArch from "../Logo/MonARCH.svg";
import { Link, useLocation } from "react-router-dom";
import Title from "./Actions/Title";
import { AppContext } from "../../App";

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        background-color:#232A58;
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: left;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
           left:0;
            width: auto;
        }
        z-index: 10;
`
);

const SlandingLine = styled(Box)(
  ({ theme }) => `
    width: 68px;
    height: 47px;
    border-bottom: 3px solid #F18A00;
    -webkit-transform:
      translateY(20px)
      translateX(-18px)
      rotate(-67deg);
    position: absolute;
    top: -23px;
    left: 130px;
    z-index: 10;
`
);

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
        border-right: 2px solid rgba(0, 0, 0, 0.15);
        margin-left:10px;
`
);

const LogoTextWrapper = styled(Box)(
  ({ theme }) => `
        padding-left: ${theme.spacing(0.2)};
        text-align:center
`
);

const VersionBadge = styled(Box)(
  ({ theme }) => `
        background: ${theme.palette.primary.main};
        color: ${theme.palette.success.contrastText};
        padding: ${theme.spacing(0.4, 1)};
        border-radius: ${theme.general.borderRadiusSm};
        text-align: center;
        display: inline-block;
        line-height: 1;
        font-size: ${theme.typography.pxToRem(11)};
`
);

const LogoText = styled(Box)(
  ({ theme }) => `
        font-size: ${theme.typography.pxToRem(20)};
        font-weight: ${theme.typography.fontWeightBold};
     
`
);
const LogoContainer = styled(Box)(
  ({ theme }) => `
        display:flex;
        flex-direction:column;
`
);

const useStyles = makeStyles((theme) => ({
  centerDiv: {
    "&.MuiBox-root": {
      width: "100%",
      height: "100%",
      backgroundColor: "#293368",
      position: "relative",

      "&:before": {
        content: "' '",
        position: "absolute",
        top: "0",
        left: "0",
        width: "0",
        height: "0",
        borderStyle: "solid",
        borderWidth: "60px 25px 0px 0px",
        borderColor: "#232A58 transparent transparent transparent",
      },

      "&:after": {
        content: "' '",
        position: "absolute",
        bottom: "0",
        right: "0",
        width: "0",
        height: "0",
        borderStyle: "solid",
        borderWidth: "0 0 55px 25px",
        borderColor: "transparent transparent #232A58 transparent",
      },
    },
  },
}));

//   width: 62,
//   height: 34,
//   padding: 7,
//   "& .MuiSwitch-switchBase": {
//     margin: 1,
//     padding: 0,
//     transform: "translateX(6px)",
//     "&.Mui-checked": {
//       color: "#fff",
//       transform: "translateX(22px)",
//       "& .MuiSwitch-thumb:before": {
//         backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
//           "#fff"
//         )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
//       },
//       "& + .MuiSwitch-track": {
//         opacity: 1,
//         backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
//       },
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
//     width: 32,
//     height: 32,
//     "&:before": {
//       content: "''",
//       position: "absolute",
//       width: "100%",
//       height: "100%",
//       left: 0,
//       top: 0,
//       backgroundRepeat: "no-repeat",
//       backgroundPosition: "center",
//       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
//         "#fff"
//       )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
//     },
//   },
//   "& .MuiSwitch-track": {
//     opacity: 1,
//     backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
//     borderRadius: 20 / 2,
//   },
// }));

function Header() {
  const [appMode, setAppMode] = useState("light");
  const userSettings = useSelector((state: any) => state.usersList);
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const appContextProps: any = React.useContext(AppContext); //consuming context created in App.tsx
  const archUsersList = useSelector((state: any) => state.archUsersList);
  const plantsList = useSelector((state: any) => state.plantsList);
  const [selectPlant, setSelectPlant] = React.useState(
    plantsList.data.filter((data: any) => {
      return data.plant_code === appContextProps.selectedPlantForCube;
    })[0] || null
  );
  //useEffect to update dropdown value to match selected plant based on user role
  React.useEffect(() => {
    setSelectPlant(
      plantsList.data.filter((data: any) => {
        return data.plant_code === appContextProps.selectedPlantForCube;
      })[0] || null
    );
  }, [appContextProps.selectedPlantForCube]);

  const classes = useStyles();

  useEffect(() => {
    setAppMode(userSettings.currentUser.themePreference);
  }, [userSettings.currentUser.themePreference]);

  const { pathname } = useLocation();

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box display="flex" alignItems="center">
        <IconButton
          style={{ color: "white" }}
          onClick={() => {
            toggleSidebar();
          }}
        >
          {!sidebarToggle ? (
            <Tooltip arrow title="Menu">
              <MenuTwoToneIcon />
            </Tooltip>
          ) : (
            <Tooltip arrow title="Close">
              <CloseTwoToneIcon />
            </Tooltip>
          )}
        </IconButton>
        <Logo />
        <SlandingLine></SlandingLine>
      </Box>
      <Box className={classes.centerDiv}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Stack direction="row" alignItems="center">
            <LogoWrapper to="/">
              <img
                src={appMode === "dark" ? LogoMonArch : LogoMonArch}
                style={{ width: 150, marginLeft: 16 }}
                alt="MonARCH"
              />
            </LogoWrapper>
            <Title />
          </Stack>
          {archUsersList.accesiblePlants &&
          pathname !== "/asset-onboarding" &&
          pathname !== "/migration" ? (
            <Grid item xs={12} marginRight={5}>
              <Autocomplete
                options={archUsersList.accesiblePlants
                  .slice()
                  .sort((a: any, b: any) =>
                    a.plant_name.toLowerCase() > b.plant_name.toLowerCase()
                      ? 1
                      : b.plant_name.toLowerCase() > a.plant_name.toLowerCase()
                      ? -1
                      : 0
                  )}
                getOptionLabel={(option: any) => option.plant_name}
                value={selectPlant}
                onChange={(event: any, newValue: any) => {
                  setSelectPlant(newValue);
                  appContextProps.setSelectedPlantForCube(newValue.plant_code);
                }}
                renderInput={(params: any) => (
                  <TextField {...params} placeholder="Select Plant" />
                )}
                disableClearable
                sx={{ width: "160px" }}
              />
            </Grid>
          ) : null}
          {/* <Hidden smDown>
              <LogoTextWrapper>
                <LogoText>
                   ARCH (Niagara Bottling){" "} 
                  <VersionBadge>{env.environment}</VersionBadge>
                </LogoText>
              </LogoTextWrapper>
            </Hidden> 
            */}
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="flex-end"
        style={{ justifyContent: "flex-end" }}
      >
        <Userbox />
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
