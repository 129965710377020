import { useContext } from "react";
import { SidebarContext } from "../../contexts/SidebarContext";
import Logo from "../Logo";

import { Box, Drawer, Hidden } from "@mui/material";
import { styled } from "@mui/material/styles";
import SidebarMenu from "../SidebarMenu";

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: 300px;
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        height: 100%;
        margin-top: 60px;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            position: fixed;
            z-index: 10;
          }
          `
);
// box-shadow: ${theme.sidebar.boxShadow};
// border-top-right-radius: ${theme.general.borderRadius};
// border-bottom-right-radius: ${theme.general.borderRadius};

const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        height: 60px;
        align-items: center;
        margin: 0 ${theme.spacing(2)} ${theme.spacing(2)};
        border-bottom: ${theme.sidebar.dividerBg} solid 1px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();

  return (
    <>
      {/* <Hidden lgDown> */}
      {/* <SidebarWrapper> */}
      {/* <Scrollbars autoHide> */}
      {/* <TopSection><Logo /></TopSection> */}
      {/* <SidebarMenu /> */}
      {/* </Scrollbars> */}
      {/* </SidebarWrapper> */}
      {/* </Hidden> */}
      {/* <Hidden lgUp> */}
      <Drawer
        anchor="left"
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          {/* <Scrollbars autoHide> */}
          {/* <TopSection></TopSection> */}
          <SidebarMenu />
          {/* </Scrollbars> */}
        </SidebarWrapper>
      </Drawer>
      {/* </Hidden> */}
    </>
  );
}

export default Sidebar;
