import axios from "../../config/axiosConfigArch";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  loadDevices,
  loadDevicesSuceess,
  loadDevicesError,
} from "./DevicesSlice";

const getDevicesData = async () => {
  let levels: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`devices?pageSize=100&page=${page++}`);
    levels = levels.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);

  return levels;
};

function* listDevicesData(): any {
  try {
    const data = yield call(() =>
      getDevicesData().then((res) => {
        return res;
      })
    );

    yield put({
      type: loadDevicesSuceess.toString(),
      payload: data,
    });
  } catch (error) {
    yield put({
      type: loadDevicesError.toString(),
      payload: error,
    });
  }
}

export function* DevicesSaga() {
  yield takeLatest(loadDevices.toString(), listDevicesData);
}
