import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface VisualsState {
  loading: boolean;
  data: object;
  error: object;
}

const initialState: VisualsState = {
  loading: false,
  data: [],
  error: {},
};

export const VisualsSice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    // actions
    loadVisuals: (state) => {
      return {
        ...state,
        loading: true,
        data: [],
        error: {},
      };
    },
    loadVisualsSuceess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {},
      };
    },
    loadVisualsError: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loadVisuals, loadVisualsSuceess, loadVisualsError } =
  VisualsSice.actions;

export const VisualsReducer = VisualsSice.reducer;
