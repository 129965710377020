import { ReactNode } from "react";
import { ReactComponent as AccountSettingsIcon } from "../../Icons/AccountSettingsIcon.svg";
import { ReactComponent as AssetIcon } from "../../Icons/AssetIcon.svg";
import { ReactComponent as BookmarksIcon } from "../../Icons/BookmarksIcon.svg";
import { ReactComponent as DashboardMappingIcon } from "../../Icons/DashboardMappingIcon.svg";
import { ReactComponent as FacilitiesIcon } from "../../Icons/FacilitiesIcon.svg";
import { ReactComponent as TicketsIcon } from "../../Icons/TicketsIcon.svg";

import { ReactComponent as SidepanelIconAlias } from "../../Icons/SidepanelIconAlias.svg";
import { ReactComponent as SidepanelIconDashboard } from "../../Icons/SidepanelIconDashboard.svg";
import { ReactComponent as SidePanelIconKPI } from "../../Icons/SidePanelIconKPI.svg";
import { ReactComponent as SidePanelIconQueryBuilder } from "../../Icons/SidePanelIconQueryBuilder.svg";
import { ReactComponent as SidePanelIconSeed } from "../../Icons/SidePanelIconSeed.svg";
import { ReactComponent as SidePanelIconVisual } from "../../Icons/SidePanelIconVisual.svg";

export interface MenuItem {
  items?: MenuItem[];
  name: string;
  link?: string;
  icon?: ReactNode;
  badge?: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItemList = [
  // {
  //   heading: "Locations",
  //   items: [
  //     {
  //       name: "Facilities",
  //       link: "/locations/list",
  //       icon: FacilitiesIcon,
  //     },
  //     {
  //       name: "Bookmarks",
  //       link: "/bookmarks",
  //       icon: BookmarksIcon,
  //     },
  //   ],
  // },
  // {
  //   heading: "Management",
  //   items: [
  //     {
  //       name: "Assets",
  //       icon: AssetIcon,
  //       link: "/assets",
  //       items: [
  //         {
  //           name: "Mapping",
  //           link: "/asset-management/mapping",
  //         },
  //         {
  //           name: "Pool",
  //           link: "/asset-management/pool",
  //         },
  //         {
  //           name: "Usecase",
  //           link: "/asset-management/usecase",
  //         },
  //         {
  //           name: "Types",
  //           link: "/asset-management/types",
  //         },
  //       ],
  //     },

  //     {
  //       name: "Dashboard Mapping",
  //       icon: DashboardMappingIcon,
  //       link: "/dashboard-mapping",
  //     },
  //   ],
  // },
  // {
  //   heading: "Alerts",
  //   items: [
  //     {
  //       name: "Tickets",
  //       link: "/maintenance/tickets",
  //       icon: TicketsIcon,
  //     },
  //   ],
  // },

  {
    heading: "Monarch",
    items: [
      {
        name: "Visual View",
        icon: SidePanelIconVisual,
        link: "/visualview",
      },
      {
        name: "Alias View",
        icon: SidepanelIconAlias,
        link: "/aliasview",
      },
      {
        name: "Query Builder",
        icon: SidePanelIconQueryBuilder,
        link: "/queryplayground",
      },
      {
        name: "KPI Builder",
        icon: SidePanelIconKPI,
        link: "/kpibuilder/list",
      },
      {
        name: "Dashboard Builder",
        icon: SidepanelIconDashboard,
        link: "/dashboardbuilder",
      },
      {
        name: "Asset Onboarding",
        icon: SidepanelIconAlias,
        link: "/asset-onboarding",
      }
    ],
  },
];

export { menuItemList };
