import * as React from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import {
    TextField,
    Typography,
    Tooltip,
    IconButton,
    Theme
} from "@mui/material";
import PivotList from './PivotList';
import { makeStyles } from "@mui/styles";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const useStyles = makeStyles((theme: Theme) => ({
    helpText: {
      "&.css-6btap8-MuiButtonBase-root-MuiButton-root":{
        padding: "5px 5px 5px 10px"
      }
    }
  }));

export default function SeetingPivot({updatePivotConfig, pivotConfig, helpText}:any) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const [searchText, setSearchText] = React.useState('');
    return (
        <>
            <Button variant="outlined" onClick={handleClick} className={classes.helpText}>
                Pivot
                <Tooltip title={helpText}>
                    <IconButton  size="small">
                        <InfoOutlinedIcon sx={{ color: "white", fontSize: "18px",  marginLeft: "5px" }} />
                    </IconButton>
                </Tooltip>                 
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
           <PivotList updatePivotConfig={updatePivotConfig} pivotConfig={pivotConfig} />
            </Popover>
        </>
    );
}