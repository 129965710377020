import { ListSubheader, List } from "@mui/material";
import { useLocation, matchPath } from "react-router-dom";
import SidebarMenuItem from "./item";
import { MenuItem, menuItemList } from "./items";
import { styled } from "@mui/material/styles";
import { useContext } from "react";
import { SidebarContext } from "../../contexts/SidebarContext";
import { env } from "../../env";
import Userbox from "../Sidebar/Userbox";
const MenuWrapper = styled(List)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(1)};
    margin: "20px",
    padding: 0;

    & > .MuiList-root {
      padding: 0 ${theme.spacing(2)} ${theme.spacing(2)};
    }

    .MuiListSubheader-root {
      display:none;
      font-size: ${theme.typography.pxToRem(6)};
      padding: ${theme.spacing(0.2, 2)};
      line-height: 1.4;
      font-weight: ${theme.typography.fontWeightBold};
      background: ${theme.colors.primary.main} !important;
    }
`
);

const SubMenuWrapper = styled(List)(
  ({ theme }) => `
    &.MuiList-root {
      padding: 10px;

      .MuiList-root .MuiList-root .MuiListItem-root .MuiButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 2px ${theme.spacing(0)};
    
        .MuiButton-root {
          display: flex;
          width: 100%;
          justify-content: flex-start;
          font-weight: lighter;
          font-size: ${theme.typography.pxToRem(15)};
          padding: ${theme.spacing(2, 2)};
          position: relative;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(4)};

            .MuiBadge-standard {
              font-size: ${theme.typography.pxToRem(12)};
              color: ${theme.palette.primary.contrastText};
            }
          }
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(26)};
            margin-right: ${theme.spacing(1.5)};
          }
          
          .MuiButton-endIcon {
            margin-left: auto;
            font-size: ${theme.typography.pxToRem(22)};
          }

          &.Mui-active,
          &:hover {
       
            .MuiButton-startIcon,
            .MuiButton-endIcon {
            }
          }
        }

        &.Mui-children {
          flex-direction: column;
          line-height: 1;
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px ${theme.spacing(0)};

            .MuiButton-root {
              font-size: ${theme.typography.pxToRem(13)};
              padding: ${theme.spacing(0.5, 2, 0.5, 6.5)};
              color:#242E6F;
              &.Mui-active,
              &:hover {
              }
            }
          }
        }
      }
    }
`
);

const renderSidebarMenuItems = ({
  items,
  path,
}: {
  items: MenuItem[];
  path: string;
}): JSX.Element => (
  <SubMenuWrapper>
    {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
  </SubMenuWrapper>
);

const reduceChildRoutes: any = ({
  ev,
  path,
  item,
}: {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
}): Array<JSX.Element> => {
  const key = item.name;

  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true,
        },
        path
      )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false,
          },
          path
        )
      : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items,
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        icon={item.icon}
      />
    );
  }

  return ev;
};

function SidebarMenu() {
  const location = useLocation();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyItems: "space-between",
        width: "300px",
      }}
    >
      <div
        style={{
          padding: "20px 10px",
        }}
      >
        {menuItemList.map((section: any) => (
          <MenuWrapper
            key={section.heading}
            subheader={
              <ListSubheader disableSticky>{section.heading}</ListSubheader>
            }
            onClick={() => {
              toggleSidebar();
            }}
          >
            {renderSidebarMenuItems({
              items: section.items,
              path: location.pathname,
            })}
          </MenuWrapper>
        ))}
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            width: "92%",
            bottom: "8em",
            cursor: "pointer",
          }}
          onClick={() => {
            window.open(`${env.ARCH_APP_URL}`, "_blank", "noreferrer");
          }}
        >
          <img
            src={`${window.location.origin + "/LogoARCH.svg"}`}
            style={{
              width: "12em",
              alignSelf: "center",
              backgroundColor: "#232A58",
              padding: "0.5em 2em 1.5em 2em",
              borderRadius: "0.8em",
            }}
            alt="Niagara | MonARCH"
          />
        </div>
      </div>
      {/* <Userbox /> */}
    </div>
  );
}

export default SidebarMenu;
