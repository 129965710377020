import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { darkLightTextChange } from "../../theme/muiGlobalCss";

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "#000",
  },
}));

const SelectedMeasures = ({
  members,
  updateMethods,
  duplicateCheckRemove,
}: any) => {
  const classes = useStyles();
  return (
    <>
      {members.length > 0 && (
        <Typography gutterBottom variant="h5" className={classes.text}>
          Measures
        </Typography>
      )}
      {members.map((m: any, i: any) => (
        <Chip
          key={i}
          label={m.title}
          variant="outlined"
          onDelete={() => {
            updateMethods.remove(m);
            duplicateCheckRemove(m.name, "dimension");
          }}
        />
      ))}
    </>
  );
};

export default SelectedMeasures;
