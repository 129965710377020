import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import {
  TextField,
  Typography,
  Stack,
  Grid,
  Box,
  Container,
  ButtonGroup,
  Theme,
  Tooltip,
  IconButton
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const useStyles = makeStyles((theme: Theme) => ({
  textColor: {
    "&.MuiTypography-root": {
      color: theme.palette.mode === "dark" ? "#EEE" : "",
      fontSize: "14px",
    },
  },
  helpText: {
    "&.css-6btap8-MuiButtonBase-root-MuiButton-root":{
      padding: "5px 5px 5px 10px"
    }
  }
}));

export default function SettingOrder({ availableMembers, updateMethods, helpText }: any) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button variant="outlined" onClick={handleClick} className={classes.helpText}>
        Order
        <Tooltip title={helpText}>
            <IconButton  size="small">
                <InfoOutlinedIcon sx={{ color: "white", fontSize: "18px",  marginLeft: "5px" }} />
            </IconButton>
        </Tooltip>        
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: "30%" },
        }}
      >
        {availableMembers
          .filter((itemData: any) => itemData)
          .map((item: any, i: any) => {
            return (
              <Grid container p={1} key={i}>
                <Grid item xs={7} sm={7} md={7} p={1}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className={classes.textColor}
                  >
                    {item.title}
                  </Typography>
                </Grid>
                <Grid item xs={5} sm={5} md={5}>
                  <ButtonGroup>
                    <Button
                      name="asc"
                      variant={item.order === "asc" ? "contained" : "outlined"}
                      onClick={(e: any) =>
                        updateMethods.set(item.id, e.target.name)
                      }
                    >
                      ASC
                    </Button>
                    <Button
                      name="desc"
                      variant={item.order === "desc" ? "contained" : "outlined"}
                      onClick={(e: any) =>
                        updateMethods.set(item.id, e.target.name)
                      }
                    >
                      DESC
                    </Button>
                    <Button
                      name="none"
                      variant={item.order === "none" ? "contained" : "outlined"}
                      onClick={(e: any) =>
                        updateMethods.set(item.id, e.target.name)
                      }
                    >
                      NONE
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            );
          })}
      </Popover>
    </>
  );
}
