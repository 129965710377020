import { Theme } from "@mui/material";

// Dark and white Background change inside components
export const darkLightBgChange = (theme: Theme) => {
  return theme.palette.mode === "dark"
    ? theme.colors.alpha.black[100]
    : theme.colors.alpha.white[100];
};

export const darkBgVariation = (theme: Theme) => {
  return theme.palette.mode === "dark"
    ? theme.colors.primary.main
    : theme.colors.alpha.white[100];
};

export const darkBgVariation2 = (theme: Theme) => {
  return theme.palette.mode === "dark" ? theme.colors.primary.main : "#EEE";
};

export const darkLightPageTopBarBorder = (theme: Theme) => {
  return theme.palette.mode === "dark"
    ? theme.colors.alpha.white[10]
    : theme.colors.alpha.black[10];
};

export const darkLightTextChange = (theme: Theme) => {
  return theme.palette.mode === "dark"
    ? theme.colors.alpha.white[100]
    : theme.colors.alpha.black[100];
};

export const returnBlack = (theme: Theme) => {
  return theme.colors.alpha.black[100];
};

export const returnWhite = (theme: Theme) => {
  return theme.palette.mode === "dark" ? theme.colors.alpha.white[100] : "";
};
