import * as React from 'react';
import {
    Grid,
} from "@mui/material";
import SettingPivot from './SettingPivot';
import SettingOrder from './SettingOrder';
import SettingLimit from './SettingLimit';

export default function SettingPanel({availableMembers, updateMethods, updatePivotConfig, pivotConfig, resetFlag, limit, setLimit}:any) {
    return(
        <Grid container spacing={2} p={3}>
            <Grid item xs={12} sm={6} md={3}>
                <SettingPivot updatePivotConfig={updatePivotConfig} pivotConfig={pivotConfig} helpText= "Configuration object that contains information about pivot axes and other options. These settings should not be modified otherwise format of data might get messed up." />
             </Grid>
             <Grid item xs={12} sm={3} md={3}>
                <SettingOrder availableMembers={availableMembers} updateMethods={updateMethods} helpText="The order parameter specifies in which order should the data be fetched. Sorting settings can be applied to any of the parameters to specify the order." />
             </Grid>
             <Grid item xs={12} sm={6} md={3}>
                <SettingLimit updatePivotConfig={updatePivotConfig} resetFlag={resetFlag} limit={limit} setLimit={setLimit} helpText="The limit parameter is used to restrict the number of results to be fetched to a certain number. By default, the limit is set to be 500." />
             </Grid>
        </Grid>
    )
}