import React, { useState, useMemo, useEffect } from "react";
import {
    Box,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    ListSubheader,
    TextField,
    InputAdornment,
    Typography,
    Grid,
    Tooltip,
    IconButton
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Chip from '@mui/material/Chip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const DateRanges = [
    {
        "name": undefined,
        "title": 'All time',
    },
    {
        "name": 'Today',
        "title": 'Today'
    },
    {
        "name": 'Yesterday',
        "title": 'Yesterday'
    },
    {
        "name": 'This week',
        "title": 'This week'
    },
    {
        "name": 'This month',
        "title": 'This month'
    },
    {
        "name": 'This quarter',
        "title": 'This quarter'
    },
    {
        "name": 'This year',
        "title": 'This year'
    },
    {
        "name": 'Last 7 days',
        "title": 'Last 7 days'
    },
    {
        "name": 'Last 30 days',
        "title": 'Last 30 days'
    },
    {
        "name": 'Last week',
        "title": 'Last week'
    },
    {
        "name": 'Last month',
        "title": 'Last month'
    },
    {
        "name": 'Last quarter',
        "title": 'Last quarter'
    },
    {
        "name": 'Last year',
        "title": 'Last year'
    }
];

const TimeGroup = ({ helpText, members, availableMembers, addMemberName, updateMethods, title, selectedCubeName, granularity, setGranularity, resetFlag }: any) => {
    const [dateRange, setdateRange] = useState('All time');
    const [timeDimension, setTimeDimension] = useState('');

    useEffect(() => {
        if (members.length > 0) {
            setTimeDimension(members[0].dimension.name);
            setdateRange(members[0].dateRange || 'All time');
            setGranularity(members[0].granularity || 'day');
        }else {
            setTimeDimension('');
        }
    }, [members]);

    useEffect(()=>{
        setTimeDimension('');
        setdateRange('All time');
        setGranularity('day');
    },[resetFlag])

    let filteredValue = [];
    if(members.length > 0){
        filteredValue = availableMembers.filter((item:any) => item.name === members[0].dimension.name);
    }else if(selectedCubeName !== ""){
        filteredValue = availableMembers.filter((item:any) => item.name.split(".")[0] === selectedCubeName);
    }

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={4}>
                <FormControl fullWidth size="small" sx={{marginBottom:"20px"}}>
                    <Typography gutterBottom variant="h5">
                        {title}
                        <Tooltip title={helpText}>
                            <IconButton>
                                <InfoOutlinedIcon sx={{ color: "white" }} />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                    <Select size="medium" value={timeDimension}>
                        {filteredValue.map((option: any, i: any) => {
                            return (<MenuItem key={option.name}
                                value={option.name}
                                onClick={(m: any) => {
                                    //updateMethods.add({ dimension: option, granularity: 'day' })
                                    if(members && members.length > 0){
                                        let setGranularityValue = granularity;
                                        //handle w/o grouping
                                        if(granularity == 'w/o grouping'){
                                            setGranularityValue = undefined;
                                        }
                                        updateMethods.update(option, { dimension: option, granularity: setGranularityValue })
                                    }else{
                                        updateMethods.add({ dimension: option, granularity: 'day' })
                                    }
                                    setTimeDimension(option.name)
                                }} >
                                {option.title}
                            </MenuItem>)
                        })}
                    </Select>
                </FormControl>
            </Grid>
            {members.map((m: any, i: any) => {
                return (
                    <React.Fragment key={i}>
                        <Grid item xs={12} sm={12} md={4}>
                            <FormControl fullWidth size="small">
                                <Typography gutterBottom variant="h5">
                                    For
                                </Typography>
                                <Select size="medium" value={dateRange}>
                                    {(DateRanges.map((item: any) => {
                                        if(item.name){
                                            item = {...item, "value": item.name}
                                        }
                                        else{
                                            item = {...item, "value": item.title}
                                        }
                                        return <MenuItem key={item.title}
                                            value={item.value}
                                            onClick={(dateRange: any) => {
                                                updateMethods.update(m, { ...m, dateRange: item.name });
                                                setdateRange(item.value);
                                            }}
                                        >
                                            {item.title}
                                        </MenuItem>
                                    }))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <FormControl fullWidth size="small">
                                <Typography gutterBottom variant="h5">
                                    By
                                </Typography>
                                <Select size="medium" value={granularity}>
                                    {(m.dimension.granularities.map((item: any) => {
                                        //handle w/o grouping
                                        if(item.name){
                                            item = {...item, "value": item.name}
                                        }
                                        else{
                                            item = {...item, "value": item.title}
                                        }
                                        return <MenuItem key={item.title}
                                            value={item.value}
                                            onClick={(granularity: any) => {
                                                updateMethods.update(m, { ...m, granularity: item.name })
                                                setGranularity(item.value)
                                            }}
                                        >
                                            {item.title}
                                        </MenuItem>
                                    }))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </React.Fragment>
                )
            })}
        </Grid>
    )
}

export default TimeGroup;