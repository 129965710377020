import axios from "../../config/axiosConfig";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  loadVisuals,
  loadVisualsSuceess,
  loadVisualsError,
} from "./VisualsSlice";
import { findDatasInSeed } from "../../utils/helper-functions/functions";

const getVisualsData = async () => {
  const response = await axios.get("/visualbuilders");
  const seedData = await axios.get("/seedconfigs"); //fetching seed data
  const queryBuilderVisuals = findDatasInSeed(
    seedData.data,
    "QueryBuilderVisuals"
  );
  const kpiBuilderVisuals = findDatasInSeed(seedData.data, "KPIBuilderVisuals");

  return response.data.map((visual: any) => {
    return {
      ...visual,
      available_in_query_builder:
        queryBuilderVisuals.seed_data_value.indexOf(visual.visual_type) !== -1,
      available_in_kpi_builder:
        kpiBuilderVisuals.seed_data_value.indexOf(visual.visual_type) !== -1,
    };
  });
};

function* listVisualsData(): any {
  try {
    const data = yield call(() =>
      getVisualsData().then((res) => {
        return res;
      })
    );

    yield put({
      type: loadVisualsSuceess.toString(),
      payload: data,
    });
  } catch (error) {
    yield put({
      type: loadVisualsError.toString(),
      payload: error,
    });
  }
}

export function* VisualsSaga() {
  yield takeLatest(loadVisuals.toString(), listVisualsData);
}
