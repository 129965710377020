import { FC, useState, createContext } from "react";
type SidebarContext = {
  sidebarToggle: any;
  toggleSidebar: () => void;
  chatboxToggle: any;
  toggleChatbox: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

export const SidebarProvider = ({ children }: any) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const [chatboxToggle, setChatboxToggle] = useState(false);
  const toggleChatbox = () => {
    setChatboxToggle(!chatboxToggle);
  };

  return (
    <SidebarContext.Provider
      value={{ sidebarToggle, toggleSidebar, chatboxToggle, toggleChatbox }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
