import axios from "../../config/axiosConfig";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  loadDashboards,
  loadDashboardsSuceess,
  loadDashboardsError,
} from "./DashboardsSlice";
// visual_type

//function to get all usecases from the api
const getDashboardsData = async () => {
  const queryBuilderDashboards = [
    "line",
    "bar",
    "column",
    "stackedColumn",
    "stackedBar",
    "table",
  ];
  const response = await axios.get("/dashboards");

  return response.data.map((visual: any) => {
    return {
      ...visual,
      available_in_query_builder:
        queryBuilderDashboards.indexOf(visual.visual_type) !== -1,
    };
  });
};

function* listDashboardsData(): any {
  try {
    const data = yield call(() =>
      getDashboardsData().then((res) => {
        return res;
      })
    );

    yield put({
      type: loadDashboardsSuceess.toString(),
      payload: data,
    });
  } catch (error) {
    yield put({
      type: loadDashboardsError.toString(),
      payload: error,
    });
  }
}

export function* DashboardsSaga() {
  yield takeLatest(loadDashboards.toString(), listDashboardsData);
}
