import React, { useState, useMemo } from "react";
import {
    Box,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    ListSubheader,
    TextField,
    InputAdornment,
    Typography,
    Tooltip,
    IconButton
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const containsText = (optionObject: any, searchText: any) => {
    if(searchText && searchText.length > 1){
        return optionObject.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    }
    return optionObject;   
}

const ControlDropDown = ({
    members, availableMembers, addMemberName, updateMethods, 
    title, duplicateCheckAdd, duplicateCheckRemove, setGranularity, helpText
}: any) => {
    const [selectedOption, setSelectedOption] = useState('');

    const [searchText, setSearchText] = useState("");
    const displayedOptions = useMemo(
        () => availableMembers.filter((option: any) => containsText(option, searchText)),
        [searchText, availableMembers]
    );
    return (
        <React.Fragment key="1">
            <FormControl fullWidth size="small">
                <Typography gutterBottom variant="h5">
                    {title}
                    <Tooltip title={helpText}>
                        <IconButton>
                        <InfoOutlinedIcon sx={{ color: "white" }} />
                        </IconButton>
                    </Tooltip>
                </Typography>
                <Select
                    // Disables auto focus on MenuItems and allows TextField to be in focus
                    MenuProps={{ autoFocus: false }}
                    labelId="search-select-label"
                    id="search-select"
                    size="medium"
                    value={selectedOption}
                    onChange={(e) => setSelectedOption("")}
                    onClose={() => setSearchText("")}
                >
                    <ListSubheader>
                        <TextField
                            size="medium"
                            // Autofocus on textfield
                            autoFocus
                            placeholder="Type to search..."
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                    e.stopPropagation();
                                }
                            }}
                        />
                    </ListSubheader>
                    {displayedOptions.map((option: any, i: any) => 
                        {
                            //remove timestamp from the option
                            if(option.shortTitle != 'Timestamp'){
                                return (
                                    <MenuItem key={option.name} value={option.name} onClick={() => {
                                        if(duplicateCheckAdd(option.name,"dimension")){
                                            updateMethods.add(option);
                                            setSearchText("");
                                            setGranularity('');
                                        }   
                                    }} >
                                        {option.title}
                                    </MenuItem>
                                )
                            }
                        }
                    )}
                </Select>
            </FormControl>
            {members.map((m: any,i:any) => (
                    <Chip key={i} label={m.title} variant="outlined"
                        onDelete={() => {
                            updateMethods.remove(m);
                            duplicateCheckRemove(m.name,"dimension");
                        }}
                         />
            ))}
        </React.Fragment>
    )
}

export default ControlDropDown;