import { useState, useEffect } from "react";
import { Box, Hidden, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import BrightnessAutoTwoToneIcon from "@mui/icons-material/BrightnessAutoTwoTone";
// import { env } from "../../env";
import LogoImage from "./Logo.svg";
import LogoImageDark from "./LogoDark.svg";
import LogoMonArch from "./MonARCH.svg";
import LogoNiagara from "./LogoNiagara.svg";
import { useSelector } from "react-redux";

const LogoSignWrapper = styled(Box)(
  () => `
        transform: scale(.8);
`
);

function Logo() {
  const [appMode, setAppMode] = useState("light");
  const userSettings = useSelector((state: any) => state.usersList);

  useEffect(() => {
    setAppMode(userSettings.currentUser.themePreference);
  }, [userSettings.currentUser.themePreference]);

  return (
    <LogoSignWrapper>
      {/* <LogoImage style={{ width: "120px" }} /> */}
      <div style={{ display: "flex" }}>
        <img
          src={appMode === "dark" ? LogoNiagara : LogoNiagara}
          style={{ width: 100, marginTop: 15 }}
          alt="Niagara | MonARCH"
        />
        {/* <img
              src={appMode === "dark" ? LogoMonArch : LogoMonArch}
              style={{ width: 150, marginLeft: 16, marginTop: -16 }}
              alt="MonARCH"
            /> */}
      </div>
    </LogoSignWrapper>
  );
}

export default Logo;
