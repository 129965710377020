import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SeedState {
  loading: boolean;
  data: object;
  error: object;
  storeSeedData: Object;
}

const initialState: SeedState = {
  loading: false,
  data: [],
  error: {},
  storeSeedData: {},
};

export const SeedSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    // actions
    loadSeed: (state) => {
      return {
        ...state,
        loading: true,
        data: [],
        error: {},
      };
    },
    loadSeedSuceess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {},
      };
    },
    intialCurrentSeedData: (state) => {
      return {
        ...state,
        storeSeedData: {},
      };
    },
    setCurrentSeedData: (state, action: PayloadAction<any>) => {
      const {
        _id: id = "",
        seed_key_name = "",
        seed_collection_type = "",
        seed_data_type = "",
        seed_data_value = [],
        seed_last_modified_by = "",
        seed_created_by = "",
        seed_active_state = false,
      } = action.payload;

      return {
        ...state,
        storeSeedData: {
          _id: id,
          seed_key_name,
          seed_collection_type,
          seed_data_type,
          seed_data_value,
          seed_last_modified_by,
          seed_created_by,
          seed_active_state,
        },
      };
    },
    loadSeedError: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadSeed,
  loadSeedSuceess,
  loadSeedError,
  intialCurrentSeedData,
  setCurrentSeedData,
} = SeedSlice.actions;

export const SeedReducer = SeedSlice.reducer;
