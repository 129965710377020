import Axios from "../../config/axiosConfig";

const SetCurrentUserImage = async (token: any) => {
  var bearer = "Bearer " + token;
  try {
    const response = await Axios.get(
      `https://graph.microsoft.com/v1.0/me/photo/$value`,
      {
        headers: {
          Authorization: bearer,
        },
        responseType: "blob",
      }
    );
    if (response != null && response.status === 200) {
      const data = response.data;
      if (data !== null) {
        window.URL = window.URL || window.webkitURL;
        return window.URL.createObjectURL(data);
      }
      return data;
    } else {
      return null;
    }
  } catch (err: any) {
    const error = new Error(err);
    console.error(error.message);
  }
};

export { SetCurrentUserImage };
