import React, { useEffect } from "react";
import PropTypes from "prop-types";
import QueryList from "./querylist";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import QueryExecutionVisual from "./queryexecutionvisual";
import { QueryBuilder } from "@cubejs-client/react";
import ControlDropDown from "./ControlDropDown";
import TimeGroup from "./TimeGroup";
import FilterGroup from "./FilterGroup";
import SettingPanel from "./SettingPanel";
import SelectedMeasures from "./SelectedMeasures";
import {
  Grid,
  Box,
  Typography,
  Button,
  Theme,
  IconButton,
} from "@mui/material";
import {
  darkBgVariation,
  darkLightBgChange,
  darkLightTextChange,
  darkBgVariation2,
} from "../../theme/muiGlobalCss";
import { useDispatch, useSelector } from "react-redux";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import Scrollbars from "react-custom-scrollbars";
import CustomScrollbars from "../../componets/CustomScrollbars/CustomScrollbars";
import { AppContext } from "../../App";
import Axios from "../../config/axiosConfigArch";
import cubejs from "@cubejs-client/core";
import { env } from "../../env";

const GridContainer = styled(Grid)(
  ({ theme }) => `
  color: ${theme.palette.text.primary};
  padding: ${theme.spacing(0, 1, 0, 0)};
  display: flex;
  text-decoration: none;
  font-weight: ${theme.typography.fontWeightBold};
  overflow: hidden;
  height:93vh;
  `
);

const useStyles = makeStyles((theme: Theme) => ({
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleContainer: {
    background: darkLightBgChange(theme),
    boxShadow:
      theme.palette.mode === "dark"
        ? "none"
        : "inset 0px -1px 0px rgba(159, 162, 191, 0.58)",
    borderRadius: theme.palette.mode === "dark" ? "5px" : "0",
    padding: "12px",
  },
  titleContainer1: {
    background: darkLightBgChange(theme),
    boxShadow:
      theme.palette.mode === "dark"
        ? "none"
        : "inset 0px -1px 0px rgba(159, 162, 191, 0.58)",
    borderRadius: theme.palette.mode === "dark" ? "5px" : "0",
  },
  titleText: {
    fontWeight: 700,
    fontSize: 13,
    color: darkLightTextChange(theme),
  },
  queryBuilder: {
    "&.MuiBox-root": {
      background: darkLightBgChange(theme),
      height: "100vh",
    },
  },
  widthOne: {
    width: "25%",
    transition: "width 0.5s ease-out",
    height: "100vh",
  },
  widthTwo: {
    width: "35%",
    transition: "width 0.8s ease",
    height: "100vh",
  },
  widthThree: {
    width: "40%",
    transition: "width 0.5s ease-out",
    height: "100vh",
  },
  fullWidthThree: {
    width: "calc(100% - 6%)",
    transition: "width 0.8s ease-out",
    height: "100vh",
  },
  halfWidthThree1: {
    width: "calc(100% - 38%)",
    transition: "width 0.5s ease-out",
    height: "100vh",
  },
  halfWidthThree2: {
    width: "calc(100% - 28%)",
    transition: "width 0.5s ease-out",
    height: "100vh",
  },
  widthNone: {
    width: "3%",
    transition: "width 0.5s ease-out",
  },
  closedMode: {
    height: "100%",
    writingMode: "vertical-rl",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    // margin: " 0 10px",
    fontSize: "16px",
    width: "100%",
  },
  iconButton: {
    "&.MuiButtonBase-root": {
      paddingLeft: "0px",
    },
  },
  iconButton1: {
    "&.MuiButtonBase-root": {
      marginTop: "12px",
    },
  },
}));

const QueryPlaygroundPage = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [limit, setLimit] = React.useState(500);
  const [granularity, setGranularity] = React.useState("day");
  const [resetFlag, setResetFlag] = React.useState<any>(false);
  const [customQuery, setCustomQuery] = React.useState<any>(null);
  const [selectedCube, setSelectedCube] = React.useState<any>(null);
  const [duplicateCheck, setDuplicateCheck] = React.useState<any>({
    dimension: [],
    filter: [],
  });
  const [firstContainer, setFirstContainer] = React.useState<boolean>(true);
  const [secondContainer, setSecondContainer] = React.useState<boolean>(true);
  const [thirdContainer, setThirdContainer] = React.useState<boolean>(true);
  const appContextProps: any = React.useContext(AppContext);
  const [context, setContext] = React.useState<any>(false);

  const fetchCubeAccessToken = (usecaseName: string) => {
    Axios.post("plants/cubeaccesstoken", {
      plant_code: usecaseName,
    }).then((res: any) => {
      const cubejsApi = cubejs(res.data.cube_access_token, {
        apiUrl: env.CUBE_API_URL!,
      });
      //updating cubejs api state with new token
      appContextProps.setCubejsApi(cubejsApi);
      return res;
    });
  };

  const resetFunction = (updateQuery: any) => {
    let blankQuery = resetQuery();
    updateQuery(blankQuery);
    setResetFlag((prevState: boolean) => !prevState);
    setLimit(500);
  };

  React.useEffect(() => {
    localStorage.setItem("isMonarchForm", "true");
    return () => {
      localStorage.removeItem("isMonarchForm");
      fetchCubeAccessToken(appContextProps.selectedPlantForCube);
    };
  }, []);

  React.useEffect(() => {
    if (appContextProps.selectedPlantForCube) {
      setSelectedCube(null);
      setCustomQuery(null);
      setContext(!context);
    }
  }, [appContextProps.selectedPlantForCube]);

  const duplicateCheckAdd = (value: any, type: string) => {
    if (duplicateCheck[type].includes(value)) {
      return false;
    } else {
      setDuplicateCheck((prevNames: any) => {
        return {
          ...prevNames,
          [type]: [...prevNames[type], value],
        };
      });
      return true;
    }
  };

  const duplicateCheckRemove = (value: any, type: string) => {
    setDuplicateCheck((prevState: any) => {
      return {
        ...prevState,
        [type]: prevState[type].filter((item: any) => item !== value),
      };
    });
  };

  const ErrorSNACKBAR = (msg: any) => {
    return dispatch({
      type: "LOAD_SNACKBAR",
      payload: {
        message: msg,
        type: "error",
        open: true,
      },
    });
  };

  const resetQuery = () => {
    return {
      measures: [],
      dimensions: [],
      timeDimensions: [],
      filters: [],
    };
  };

  const toggleOpenSecond = () => {
    dispatch({
      type: "SET_COLLAPSIBLE_DATA",
      payload: {
        second: !secondContainer,
      },
    });
    setSecondContainer(!secondContainer);
  };

  const toggleOpenThird = () => {
    setThirdContainer(!thirdContainer);
  };

  return (
    <QueryBuilder
      key="1"
      wrapWithQueryRenderer={false}
      render={({
        measures,
        availableMeasures,
        updateMeasures,
        dimensions,
        availableDimensions,
        updateDimensions,
        segments,
        availableSegments,
        updateSegments,
        filters,
        updateFilters,
        timeDimensions,
        availableTimeDimensions,
        updateTimeDimensions,
        isQueryPresent,
        chartType,
        updateChartType,
        validatedQuery,
        orderMembers,
        updateOrder,
        query,
        pivotConfig,
        updatePivotConfig,
        updateQuery,
        error,
        meta,
        refresh,
      }) => {
        if (query) {
          if (!query.filters || (query.filters && query.filters.length === 0)) {
            if (duplicateCheck["filter"].length > 0) {
              duplicateCheck["filter"].map((data: any) => {
                duplicateCheckRemove(data, "filter");
              });
            }
            // Resetting the data
            if (context) {
              resetFunction(updateQuery);
              setContext(false);
            }
          }
          if (!query.measures && !query.dimensions) {
            if (duplicateCheck["dimension"].length > 0) {
              duplicateCheck["dimension"].map((data: any) => {
                duplicateCheckRemove(data, "dimension");
              });
            }
          }
        }
        let selectedCubeMember = selectedCube ? selectedCube.measures : [];
        let selectedCubeDimension = selectedCube ? selectedCube.dimensions : [];
        let selectedCubeSegment = selectedCube ? selectedCube.segments : [];
        let selectedCubeName = selectedCube ? selectedCube.name : "";
        return (
          <GridContainer
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ backgroundColor: "#293368" }}
            spacing={0.8}
          >
            <Grid
              item
              className={firstContainer ? classes.widthOne : classes.widthNone}
            >
              <QueryList
                title="Measures"
                helpText="The measures parameter contains a set of measures and each measure is an aggregation over a certain column in your database table. In other words, measures can be considered as the value that we want to plot in a visual."
                duplicateCheckAdd={duplicateCheckAdd}
                members={measures}
                availableMembers={selectedCubeMember}
                addMemberName="Measure"
                updateMethods={updateMeasures}
                cubeMeta={meta}
                setSelectedCube={setSelectedCube}
                selectedCube={selectedCube}
                resetQuery={resetQuery}
                updateQuery={updateQuery}
                setResetFlag={setResetFlag}
                setFirstContainer={setFirstContainer}
                firstContainer={firstContainer}
                fetchCubeAccessToken={fetchCubeAccessToken}
              />
            </Grid>
            <Grid
              item
              className={`${
                secondContainer ? classes.widthTwo : classes.widthNone
              }`}
            >
              <Box sx={{ backgroundColor: "#232A58" }}>
                <Box
                  className={`${
                    secondContainer
                      ? classes.titleContainer
                      : classes.titleContainer1
                  } ${classes.flexContainer}`}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    className={classes.titleText}
                  >
                    <IconButton
                      aria-label="delete"
                      onClick={toggleOpenSecond}
                      sx={{ color: "#b2bac2" }}
                      className={
                        secondContainer
                          ? classes.iconButton
                          : classes.iconButton1
                      }
                    >
                      {secondContainer ? (
                        <KeyboardDoubleArrowLeftIcon />
                      ) : (
                        <KeyboardDoubleArrowRightIcon />
                      )}
                    </IconButton>
                    {secondContainer ? "Selected Query" : ""}
                  </Typography>
                </Box>

                {secondContainer ? (
                  <CustomScrollbars
                    autoHeight={true}
                    autoHeightMin={`calc(93vh - 50px)`}
                  >
                    <Box
                      borderTop="5px solid #293368"
                      className={classes.queryBuilder}
                    >
                      <Grid container spacing={2} p={3}>
                        <Grid item xs={12} sm={12} md={12}>
                          <SelectedMeasures
                            duplicateCheckRemove={duplicateCheckRemove}
                            members={measures}
                            updateMethods={updateMeasures}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <ControlDropDown
                            helpText="The dimensions property contains a set of dimensions. You can think about a dimension as an attribute related to a measure. In other words, dimensions can be considered as additional columns that give more info about the data point being fetched."
                            duplicateCheckAdd={duplicateCheckAdd}
                            duplicateCheckRemove={duplicateCheckRemove}
                            title="Dimensions"
                            members={dimensions}
                            availableMembers={selectedCubeDimension}
                            addMemberName="Dimension"
                            updateMethods={updateDimensions}
                            setGranularity={setGranularity}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <ControlDropDown
                            helpText="Segments are predefined filters. We can use segments to define complex filtering logic in SQL."
                            duplicateCheckAdd={duplicateCheckAdd}
                            duplicateCheckRemove={duplicateCheckRemove}
                            title="Segments"
                            members={segments}
                            availableMembers={selectedCubeSegment}
                            addMemberName="Segment"
                            updateMethods={updateSegments}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <TimeGroup
                            helpText="The time property specifies the time filter and granularity for which the data is to be fetched."
                            title="Time"
                            members={timeDimensions}
                            availableMembers={availableTimeDimensions}
                            addMemberName="Time"
                            updateMethods={updateTimeDimensions}
                            selectedCubeName={selectedCubeName}
                            granularity={granularity}
                            setGranularity={setGranularity}
                            resetFlag={resetFlag}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <FilterGroup
                            helpText="The filter parameter can be considered similar to 'WHERE' clause of an SQL query. Here we specify set of conditions according to which the data should be fetched."
                            duplicateCheckAdd={duplicateCheckAdd}
                            duplicateCheckRemove={duplicateCheckRemove}
                            members={filters}
                            availableMembers={[
                              ...selectedCubeMember,
                              ...selectedCubeDimension,
                            ]}
                            addMemberName="Filter"
                            updateMethods={updateFilters}
                            title="Filter"
                          />
                        </Grid>
                      </Grid>

                      <SettingPanel
                        availableMembers={orderMembers}
                        updateMethods={updateOrder}
                        updatePivotConfig={updatePivotConfig}
                        pivotConfig={pivotConfig}
                        resetFlag={resetFlag}
                        limit={limit}
                        setLimit={setLimit}
                      />
                      <Grid
                        container
                        spacing={2}
                        p={3}
                        justifyContent="space-between"
                      >
                        <Grid item xs={6} sm={6} md={3}>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                              resetFunction(updateQuery);
                            }}
                          >
                            Reset
                          </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                              if (error === null) {
                                let queryLimit = query.limit;
                                if (
                                  dimensions.length < 1 &&
                                  measures.length < 1
                                ) {
                                  ErrorSNACKBAR(
                                    "Select measure or dimension to get started"
                                  );
                                } else if (queryLimit && queryLimit < 1) {
                                  ErrorSNACKBAR(
                                    "limit must be larger than or equal to 1"
                                  );
                                }
                                // else if (timeDimensions.length === 0) {
                                //   ErrorSNACKBAR("Select time to get started");
                                // }
                                // else if (granularity === "") {
                                //   ErrorSNACKBAR(
                                //     "Select all fields in time to get started"
                                //   );
                                // }
                                else {
                                  let filterValidation: any = [];
                                  if (filters.length > 0) {
                                    filters.map((item: any) => {
                                      if (!item.operator || !item.values) {
                                        filterValidation.push(false);
                                      }
                                    });
                                  }
                                  if (filterValidation.includes(false)) {
                                    ErrorSNACKBAR(
                                      "Please enter all the values for filter"
                                    );
                                  } else {
                                    let updateQuery: any = { ...query };
                                    if (updateQuery.timeDimensions) {
                                      if (granularity === "w/o grouping") {
                                        updateQuery.timeDimensions[0].granularity =
                                          undefined;
                                      } else {
                                        updateQuery.timeDimensions[0].granularity =
                                          granularity;
                                      }
                                    }
                                    setCustomQuery(query);
                                  }
                                }
                              } else {
                                let message = error
                                  ? error.message
                                  : "Something went wrong";
                                ErrorSNACKBAR(message);
                              }
                            }}
                          >
                            Execute
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </CustomScrollbars>
                ) : (
                  <Box className={classes.closedMode}>Query</Box>
                )}
              </Box>
            </Grid>
            <Grid
              item
              className={
                thirdContainer && !secondContainer && !firstContainer
                  ? classes.fullWidthThree
                  : !firstContainer && secondContainer && thirdContainer
                  ? classes.halfWidthThree1
                  : firstContainer && !secondContainer && thirdContainer
                  ? classes.halfWidthThree2
                  : firstContainer && secondContainer && thirdContainer
                  ? classes.widthThree
                  : classes.widthNone
              }
            >
              <QueryExecutionVisual
                customQuery={customQuery}
                pivotConfig={pivotConfig}
                thirdContainer={thirdContainer}
                toggleOpenThird={toggleOpenThird}
                secondContainer={secondContainer}
              />
            </Grid>
          </GridContainer>
        );
      }}
    />
  );
};

export default QueryPlaygroundPage;
