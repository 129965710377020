import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UsecaseState {
  loading: boolean;
  data: object;
  error: object;
}

const initialState: UsecaseState = {
  loading: false,
  data: [],
  error: {},
};

export const UsecaseSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    // actions
    loadUsecase: (state) => {
      return {
        ...state,
        loading: true,
        data: [],
        error: {},
      };
    },
    loaduseCaseSuceess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {},
      };
    },
    loaduseCaseError: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loadUsecase, loaduseCaseSuceess, loaduseCaseError } =
  UsecaseSlice.actions;

export const useCaseReducer = UsecaseSlice.reducer;
