import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import { alertClose } from "../../Redux/Alerts/AlertSlice";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface CustomizedSnackbarsProps {
  type: string;
  message: string;
  vertical?: string;
  horizontal?: string;
  open: boolean;
}

const CustomizedSnackbars: React.FC<CustomizedSnackbarsProps> = (
  props: any
) => {
  const dispatch = useDispatch();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(alertClose());
  };

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: props.vertical, horizontal: props.horizontal }}
      sx={{ zIndex: 1000000 }}
    >
      <Alert onClose={handleClose} severity={props.type} sx={{ width: "100%" }}>
        {props.message}
      </Alert>
    </Snackbar>
  );
};

CustomizedSnackbars.defaultProps = {
  vertical: "top",
  horizontal: "center",
};

export default CustomizedSnackbars;
