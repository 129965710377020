import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MachinesState {
  loading: boolean;
  data: object;
  error: object;
}

const initialState: MachinesState = {
  loading: false,
  data: [],
  error: {},
};

export const MachinesSice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    // actions
    loadMachines: (state) => {
      return {
        ...state,
        loading: true,
        data: [],
        error: {},
      };
    },
    loadMachinesSuceess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {},
      };
    },
    loadMachinesError: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loadMachines, loadMachinesSuceess, loadMachinesError } =
  MachinesSice.actions;

export const MachinesReducer = MachinesSice.reducer;
