import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomTable from "./CustomTable";

const RootContainer = styled("div")(
  ({ theme }) => `
  margin-top: 10px; 
  padding: 20px;
`
);

function CustomKPICard(props: any) {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#232A58",
        paddingTop: "25px 0px",
      }}
    >
      <Box
        sx={{
          width: props.options.chart.width,
          height: props.options.chart.height,
          backgroundColor: props.options.chart.backgroundColor,
          margin: "auto",
          border: `${props.options.border.width} ${props.options.border.style} ${props.options.border.color}`,
        }}
      >
        {props.options.header.position === "top" ? (
          <Typography
            style={{
              fontWeight: props.options.header.style.fontWeight,
              color: props.options.header.style.color,
              fontSize: props.options.header.style.fontSize,
              textAlign: props.options.header.align,
            }}
          >
            {props.header}
          </Typography>
        ) : (
          <></>
        )}

        <Typography
          style={{
            fontWeight: props.options.data.style.fontWeight,
            color: props.options.data.style.color,
            fontSize: props.options.data.style.fontSize,
            textAlign: props.options.data.align,
          }}
        >
          {props.value}
        </Typography>

        {props.options.header.position === "bottom" ? (
          <Typography
            style={{
              fontWeight: props.options.header.style.fontWeight,
              color: props.options.header.style.color,
              fontSize: props.options.header.style.fontSize,
              textAlign: props.options.header.align,
            }}
          >
            {props.header}
          </Typography>
        ) : (
          <></>
        )}
      </Box>
    </div>
  );
}

export default CustomKPICard;
