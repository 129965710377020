/**{
This function is used to get a particular object in a seed Data API
@Params - Data is the list of seed Data , Key name is the object we want from the list 
} **/
const findDatasInSeed: any = (data: [], keyName: string) => {
  const result = data.find((x: any) => {
    const { seed_key_name = "" } = x;
    if (seed_key_name === keyName) {
      return x;
    }
  });
  return result;
};

export { findDatasInSeed };
