import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UsersState {
  loading: boolean;
  data: object;
  error: object;
  currentUser: object;
  currentUserImage: string | null;
}

const initialState: UsersState = {
  loading: false,
  data: [],
  error: {},
  currentUser: {
    themePreference: "dark",
  },
  currentUserImage: null,
};

export const UsersSlice = createSlice({
  name: "Users",
  initialState,
  reducers: {
    // actions
    loadUsers: (state) => {
      return {
        ...state,
        loading: true,
        data: [],
        error: {},
      };
    },
    loadUsersSuceess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {},
      };
    },
    loadUsersError: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    },
    setCurrentUserPreference: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        currentUser: action.payload,
      };
    },
    loadCurrentUserImage: (state) => {
      return {
        ...state,
        loading: true,
        currentUserImage: null,
      };
    },
    loadCurrentUserSuccess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        currentUserImage: action.payload,
      };
    },
    loadCurrentUserError: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        currentUserImage: null,
        error: action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadUsers,
  loadUsersSuceess,
  loadUsersError,
  setCurrentUserPreference,
  loadCurrentUserImage,
  loadCurrentUserSuccess,
  loadCurrentUserError,
} = UsersSlice.actions;

export const UsersReducer = UsersSlice.reducer;
