import axios from "../../config/axiosConfigArch";
import { takeLatest, put, call } from "redux-saga/effects";
import { loadPlants, loadPlantsSuceess, loadPlantsError } from "./PlantsSlice";

const getPlantsData = async () => {
  let levels: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`plants?pageSize=100&page=${page++}`);
    levels = levels.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);

  return levels.sort((a: any, b: any) =>
    a.plant_name.toLowerCase() > b.plant_name.toLowerCase()
      ? 1
      : b.plant_name.toLowerCase() > a.plant_name.toLowerCase()
      ? -1
      : 0
  );
};

function* listPlantsData(): any {
  try {
    const data = yield call(() =>
      getPlantsData().then((res) => {
        return res;
      })
    );

    yield put({
      type: loadPlantsSuceess.toString(),
      payload: data,
    });
  } catch (error) {
    yield put({
      type: loadPlantsError.toString(),
      payload: error,
    });
  }
}

export function* PlantsSaga() {
  yield takeLatest(loadPlants.toString(), listPlantsData);
}
