import axios from "../../config/axiosConfigArch";
import { takeLatest, put, call } from "redux-saga/effects";
import { loadAssets, loadAssetsSuceess, loadAssetsError } from "./AssetsSlice";

const getAssetsData = async () => {
  let levels: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`assets?pageSize=750&page=${page++}`);
    levels = levels.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);

  return levels;
};

function* listAssetsData(): any {
  try {
    const data = yield call(() =>
      getAssetsData().then((res) => {
        return res;
      })
    );

    yield put({
      type: loadAssetsSuceess.toString(),
      payload: data,
    });
  } catch (error) {
    yield put({
      type: loadAssetsError.toString(),
      payload: error,
    });
  }
}

export function* AssetsSaga() {
  yield takeLatest(loadAssets.toString(), listAssetsData);
}
