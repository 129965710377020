import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface QueryState {
  loading: boolean;
  data: object;
  error: object;
  queryCollapsible: Object;
}

const initialState: QueryState = {
  loading: false,
  data: [],
  error: {},
  queryCollapsible: {
    firstContainer: true,
    secondContainer: true,
  },
};

export const QuerySlice = createSlice({
  name: "Query",
  initialState,
  reducers: {
    // actions
    loadQuery: (state) => {
      return {
        ...state,
        loading: true,
        data: [],
        error: {},
        queryCollapsible: {
          firstContainer: true,
          secondContainer: true,
        },
      };
    },
    updateCollapsible: (state: any, action: PayloadAction<any>) => {
      let first = true;
      let second = true;
      if (action.payload.first === true || action.payload.first === false) {
        first = action.payload.first;
        second = state.queryCollapsible.secondContainer;
      } else {
        first = state.queryCollapsible.firstContainer;
        second = action.payload.second;
      }
      return {
        ...state,
        loading: true,
        data: [],
        error: {},
        queryCollapsible: {
          firstContainer: first,
          secondContainer: second,
        },
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loadQuery, updateCollapsible } = QuerySlice.actions;

export const QueryReducer = QuerySlice.reducer;
