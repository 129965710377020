import React, { useState } from "react";
import {
  TextField,
  Typography,
  Autocomplete,
  Chip,
  Tooltip,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "#000",
  },
}));

const FilterInput = ({ member, updateMethods, values }: any) => {
  const [searchText, setSearchText] = useState(member.values);
  const classes = useStyles();

  return (
    <>
      <Typography gutterBottom variant="h5" className={classes.text}>
        Value
      </Typography>
      <Autocomplete
        size="medium"
        multiple
        options={[]}
        defaultValue={values || []}
        freeSolo
        onChange={(e, value: any) => {
          if (value.length > 0) {
            updateMethods.update(member, { ...member, values: value });
          }
        }}
        renderTags={(
          value: any[],
          getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes
        ) =>
          value.map((option: any, index: any) => {
            return (
              <Tooltip title={option}>
                <Chip
                  key={index}
                  variant="outlined"
                  title={option}
                  label={option}
                  {...getTagProps({ index })}
                />
              </Tooltip>
            );
          })
        }
        renderInput={(params: any) => (
          <TextField size="small" {...params} placeholder="Value" />
        )}
      />
      {/* <TextField
                size="small"
                autoFocus
                placeholder="Filter Value"
                fullWidth
                value={searchText}
                onChange={(e: any) => {
                    let textValue = e.target.value;
                    updateMethods.update(member, { ...member, values: [textValue] })
                    setSearchText(e.target.value)
                }}
                onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                        e.stopPropagation();
                    }
                }}
            /> */}
    </>
  );
};

export default FilterInput;
