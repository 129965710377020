import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";

export default ({ categories, data, selectedVisual }: any) => {
  const [options, setOptions] = useState({});
  const [appMode, setAppMode] = useState("dark");
  const highChartRef = React.useRef<any>(null);
  const containerRef = React.useRef<any>(null);
  const queryList = useSelector((state: any) => state.queryList);
  const userSettings = useSelector((state: any) => state.usersList);
  const getObjectFromElement = (obj: any) => {
    if (obj.hasOwnProperty("subproperties")) {
      const subPropertyArray = obj["subproperties"].map((ele: any) => {
        return getObjectFromElement(ele);
      });
      const groupedObj = Object.assign({}, ...subPropertyArray);
      const finalPropObject = { [obj["property"]]: groupedObj };
      return finalPropObject;
    } else {
      const finalPropObject = { [obj["property"]]: obj["value"] };
      return finalPropObject;
    }
  };

  const temp = selectedVisual.visual_config.map((ele: any) => {
    return getObjectFromElement(ele);
  });

  const visualConfig = Object.assign({}, ...temp);
  const [config, setConfig] = React.useState(visualConfig);
  useEffect(() => {
    setOptions({
      ...config,
      chart: {
        type: config.chart.type,
        height: "700vh",
        styledMode: true,
        spacingRight: 25,
        spacingLeft: 20,
        zoomType: "x",
        backgroundColor: "#232A58",
        plotBackgroundColor: "#232A58",
      },
      title: {
        useHTML: true,
        text: "",
      },
      xAxis: {
        categories: categories,
        labels: {
          // rotation: -90,
          style: {
            color: appMode === "dark" ? "#FFF" : "#000",
          },
        },
        tickInterval: 1,
      },
      yAxis: {
        ...config.yAxis,
        labels: {
          style: {
            color: appMode === "dark" ? "#FFF" : "#000",
          },
        },
      },
      tooltip: {
        backgroundColor: "#FFF",
        style: {
          ...config.tooltip.style,
          color: "#000",
        },
      },
      series: data,
    });
  }, [data, categories, appMode]);

  useEffect(() => {
    setAppMode(userSettings.currentUser.themePreference);
    if (userSettings.currentUser.themePreference === "dark") {
      DarkUnica(Highcharts);
    }
  }, [userSettings.currentUser.themePreference]);

  useEffect(() => {
    const timer = setTimeout(() => {
      highChartRef?.current?.chart?.setSize(
        containerRef.current.clientWidth,
        containerRef.current.clientHeight
      );
      return () => {
        clearTimeout(timer);
      };
    }, 800);
  }, [
    queryList?.queryCollapsible?.secondContainer,
    queryList?.queryCollapsible?.firstContainer,
  ]);

  return (
    <div
      style={{
        height: "80vh",
        padding: "10px",
        margin: "0 auto",
      }}
      ref={containerRef}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={highChartRef}
      />
    </div>
  );
};
