import { all } from "redux-saga/effects";
import { AlertSaga } from "./Alerts/AlertSaga";
import { SeedSaga } from "./Seed/SeedSaga";
import { UsersSaga } from "./UserPreference/UsersSaga";
import { VisualsSaga } from "./Visuals/VisualsSaga";
import { AliasSaga } from "./Alias/AliasSaga";
import { AssetsSaga } from "./Assets/AssetsSaga";
import { KPISaga } from "./KPIs/KPISaga";
import { DevicesSaga } from "./Devices/DevicesSaga";
import { SensorsSaga } from "./Sensors/SensorsSaga";
import { MachinesSaga } from "./Machines/MachinesSaga";
import { PlantsSaga } from "./Plants/PlantsSaga";
import { ArchUsersSaga } from "./Users/ARCHUsersSaga";
import { QuerySaga } from "./Query/QuerySaga";
import { DashboardsSaga } from "./Dashboards/DashboardsSaga";

import { LevelTypesSaga } from "./LevelTypes/LevelTypesSaga";
import { AssetTypesSaga } from "./AssetTypes/AssetTypesSaga";
import { UsecaseSaga } from "./UseCase/UseCaseSaga";
import { OEMsSaga } from "./OEMs/OEMSaga";
import { ModelSaga } from "./Models/ModelSaga";

export default function* rootSaga() {
  yield all([
    AssetsSaga(),
    AlertSaga(),
    SeedSaga(),
    VisualsSaga(),
    AliasSaga(),
    UsersSaga(),
    KPISaga(),
    PlantsSaga(),
    MachinesSaga(),
    SensorsSaga(),
    DevicesSaga(),
    AssetTypesSaga(),
    LevelTypesSaga(),
    QuerySaga(),
    DashboardsSaga(),
    UsecaseSaga(),
    ArchUsersSaga(),
    OEMsSaga(),
    ModelSaga()
  ]);
}
