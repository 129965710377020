import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import moment from 'moment';
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

import { acquireTokenSilent, userInfo } from '../../authService';
import Alert from '../../componets/Modal/index'
const SessionTimeout = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const [events, setEvents] = useState(["load",
    "click",
    "scroll",
    "keypress"]);
  const [time, setTime] = useState(300*60);
  const [isOpen, setOpen] = useState(false);

  let timeStamp: any;
  let warningInactiveInterval: any = useRef();
  let startTimerInterval: any = useRef();

  // check for inactivity
  // updated inactivity from 10 minutes to 6 hours

  const SIX_HOURS = 6 * 60 * 60 * 1000; // in ms
  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp: any = sessionStorage.getItem('lastTimeStampMonarch');
      warningInactive(storedTimeStamp);
    }, SIX_HOURS);
  };

  // check for warning timer
  let warningInactive = (timeString: string) => {
    clearTimeout(startTimerInterval.current);
    const maxTime = 360;
    const popTime = 300;
    let time = popTime * 60;
    warningInactiveInterval.current = setInterval(() => {
   

      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes();

      if(minPast >= popTime){
        setTime(time--)
      }

      if (minPast === popTime) {
        setOpen(true);
      }

      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        setOpen(false);
        sessionStorage.removeItem('lastTimeStampMonarch');
        logOut()
      }
    }, 1000);
  };

  // check for reset interval timer
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);
    if (userInfo.userIsAuthenticated) {
      timeStamp = moment();
      sessionStorage.setItem('lastTimeStampMonarch', timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem('lastTimeStampMonarch');
      localStorage.removeItem('isMonarchForm')
    }
    timeChecker();
    setOpen(false);
  }, [userInfo.userIsAuthenticated]);

  const handleClose = () => {
    setOpen(false);
    resetTimer();
    logOut();
  };

  const handleContinue = () => {
    if (timeStamp >= 17970 && localStorage.getItem("isMonarchForm")) {
      setOpen(false);
      resetTimer();
    } else if (time < 17970 && localStorage.getItem("isMonarchForm")) {
      //window.location.reload()
      setOpen(false);
      resetTimer();
      navigate("/");
    } else {
      setOpen(false);
      acquireTokenSilent();
      resetTimer();
    }
  }

  const logOut = () => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, events, timeChecker]);


  if (!isOpen) {
    return null;
  }

  return <Alert
    open={isOpen}
    handleClose={handleClose}
    handleContinue={handleContinue}
    setOpen={setOpen}
    time={time}
  />;
};

export default SessionTimeout;
