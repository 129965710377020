export const env = {
  MONARCH_API_BASE_URL: process.env.REACT_APP_MONARCH_API_BASE_URL,
  ARCH_API_BASE_URL: process.env.REACT_APP_ARCH_API_BASE_URL,
  ARCH_APP_URL: process.env.REACT_APP_ARCH_APP_URL,
  environment: process.env.REACT_APP_ENVIRONMENT,
  CUBE_API_URL: process.env.REACT_APP_CUBE_API_URL,
  MONARCH_DEV_URL: process.env.REACT_APP_MONARCH_API_BASE_URL_DEV,
  MONARCH_QA_URL: process.env.REACT_APP_MONARCH_API_BASE_URL_QA,
  MONARCH_UAT_URL: process.env.REACT_APP_MONARCH_API_BASE_URL_UAT,
};
