import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AssetTypesState {
  loading: boolean;
  data: object;
  error: object;
}

const initialState: AssetTypesState = {
  loading: false,
  data: [],
  error: {},
};

export const AssetTypesSice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    // actions
    loadAssetTypes: (state) => {
      return {
        ...state,
        loading: true,
        data: [],
        error: {},
      };
    },
    loadAssetTypesSuceess: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {},
      };
    },
    loadAssetTypesError: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loadAssetTypes, loadAssetTypesSuceess, loadAssetTypesError } =
  AssetTypesSice.actions;

export const AssetTypesReducer = AssetTypesSice.reducer;
