import { Box } from "@mui/material";
// import HeaderNotifications from "./Notifications";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MessageIcon from "@mui/icons-material/Message";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";

const TitleTypography = styled(Typography)(
  ({ theme }) => `
  color: ${theme.header.textColor};
  display: "block";
  font-weight: 700;
`
);
function Title() {
  const { pathname } = useLocation();

  let getTitle = () => {
    switch (pathname) {
      case "/seedconfigurator":
        return "Seed Configurator";
      case "/visualview":
        return "Visual View";
      case "/aliasview":
        return "Alias View";
      case "/queryplayground":
        return "Query Builder & Playground";
      case "/kpibuilder/list":
        return "KPI List";
      case "/kpibuilder":
        return "KPI Builder";
      case "/dashboardbuilder":
        return "Dashboard Builder";
      case "/usermanagement":
        return "User Management";
      case "/roles":
        return "Roles";
      case "/asset-onboarding":
        return "Asset Onboarding";
      case "/migration":
        return "Migration";

      default:
        return "Home";
    }
  };

  return (
    <>
      <TitleTypography style={{ fontSize: "12px", marginLeft: "10px" }}>
        {getTitle()}
      </TitleTypography>
    </>
  );
}

export default Title;
