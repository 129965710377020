import Popover from "@mui/material/Popover";
import CardContent from "@mui/material/CardContent";
import FilterAltTwoToneIcon from "@mui/icons-material/FilterAltTwoTone";
import * as React from "react";
import { FC, useState } from "react";
import {
  TextField,
  Divider,
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  TableFooter,
  Button,
  Stack,
} from "@mui/material";
import FilterListTwoToneIcon from "@mui/icons-material/FilterListTwoTone";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { makeStyles } from "@mui/styles";
import { CSVLink, CSVDownload } from "react-csv";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles(() => ({
  progressSpinner: {
    marginTop: window.innerHeight * 0.25,
    marginBottom: window.innerHeight * 0.2,
    marginLeft: window.innerWidth * 0.4,
  },
  searchFieldContainer: {
    margin: "0px 0px 10px",
  },
  searchField: {
    width: "200px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "28px",
      color: "#ffffff",
    },
  },
  tablePointer: {
    "&.MuiTypography-root": {
      cursor: "pointer",
    },
  },
  iconColor: {
    "&.MuiIconButton-root": {
      // color: "#1976d2",
    },
  },
  iconFilterButtonBg: {
    "&.MuiIconButton-root": {
      // color: "#1976d2",
      // backgroundColor: "#E5E7F1",
    },
  },
  iconFilterButtonNonBg: {
    "&.MuiIconButton-root": {
      // color: "#1976d2",
      // backgroundColor: "transparent",
    },
  },
}));

const CustomTable: FC<any> = (props: any) => {
  const classes = useStyles();
  const [page, setPage] = useState<number>(0); //state to store current page
  const [limit, setLimit] = useState<number>(10); //state to store limit
  const [filters, setFilters] = useState<any>([]); //state to store active filters
  const [sortedColumn, setSortedColumn] = useState<string>(
    props.options.data.columns[0]?.name
  ); //state to store sorting column
  const [sortAscending, setSortAscending] = useState<boolean>(true); //state to store order of sorting
  const [rows, setRows] = React.useState(props.options.data.rows); //modifying tickets to have asset name and plant name

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null); //state to store anchor for assigned tickets menu
  const open1 = Boolean(anchorEl);
  const [anchorEl_2, setAnchorEl_2] = React.useState<null | HTMLElement>(null); //state to store anchor for non assigned tickets menu
  const open2 = Boolean(anchorEl_2);

  const [anchorEl_filter, setAnchorEl_filter] =
    React.useState<null | HTMLElement>(null); //state to store anchor for filter menu
  const openFilter = Boolean(anchorEl_filter);
  const [currentFilterColumn, setCurrentFilterColumn] = React.useState(""); //state to store current filter column
  const [currentFilterColumnAttribute, setCurrentFilterColumnAttribute] =
    React.useState(""); //state to store current filter column attribute

  //function to handle filtering
  const handleFiltering = (anchor: any, columnName: any, attribute: any) => {
    //showing or hiding menu if same filter column is clicked again
    if (columnName === currentFilterColumn) {
      setAnchorEl_filter(anchorEl_filter ? null : anchor);
    } else {
      setAnchorEl_filter(anchor);
      setCurrentFilterColumn(columnName);
      setCurrentFilterColumnAttribute(attribute);
    }
  };

  //updating filters
  const setFilterValue = (columnName: any, value: any) => {
    setPage(0);
    let tempFilter = [...filters];
    if (
      filters.find((ele: any) => {
        return ele.column === columnName;
      }) === undefined
    ) {
      setFilters([
        {
          column: columnName,
          display: "",
          value: value,
          attributeName: currentFilterColumnAttribute,
        },
        ...filters,
      ]);
    } else {
      let item = {
        ...filters.find((ele: any) => {
          return ele.column === columnName;
        }),
      };
      item.value = value;

      tempFilter[
        tempFilter.findIndex((ele: any) => {
          return ele.column === columnName;
        })
      ] = item;

      setFilters(tempFilter);
    }
  };

  //applying filters
  const applyFilters = (rows: any, filters: any) => {
    if (filters.length > 0) {
      let filteredRows = rows;
      for (const filter of filters) {
        filteredRows = filteredRows.filter((row: any) =>
          String(row[filter.column]?.toLowerCase()).includes(
            filter.value.toLowerCase()
          )
        );
      }
      return filteredRows;
    }
    return rows;
  };

  //applying pagination
  const applyPagination = (
    ticketList: any,
    page: number,
    limit: number
  ): any => {
    return ticketList.slice(page * limit, page * limit + limit);
  };

  //applying sorting
  const applySort = (
    ticketList: any,
    sortedColumn: any,
    sortAscending: boolean
  ): any => {
    const returnTicketList = sortAscending
      ? ticketList.sort((a: any, b: any) => {
          const valueA =
            a[sortedColumn] === undefined || a[sortedColumn] === null
              ? ""
              : a[sortedColumn];
          const valueB =
            b[sortedColumn] === undefined || b[sortedColumn] === null
              ? ""
              : b[sortedColumn];

          return typeof valueB === "string"
            ? valueA?.localeCompare(valueB)
            : valueA - valueB;
        })
      : ticketList.sort((a: any, b: any) => {
          const valueA =
            a[sortedColumn] === undefined || a[sortedColumn] === null
              ? ""
              : a[sortedColumn];
          const valueB =
            b[sortedColumn] === undefined || b[sortedColumn] === null
              ? ""
              : b[sortedColumn];

          return typeof valueA === "string"
            ? valueB?.localeCompare(valueA)
            : valueB - valueA;
        });
    return returnTicketList;
  };
  const filteredRows = applyFilters(rows, filters);
  const sortedRows = applySort(filteredRows, sortedColumn, sortAscending);
  const paginatedCryptoOrders = applyPagination(sortedRows, page, limit);
  //function to handle sorting click
  const handlSortClick = (columnName: string): void => {
    setSortedColumn(columnName);
    setSortAscending(sortedColumn === columnName ? !sortAscending : true);
  };

  const headers = props.options.data.columns.map((data: any) => {
    return {
      label: data.headerText,
      key: data.name,
    };
  });
  const currentDate = new Date();
  return (
    <Box
      sx={{
        width: props.options.chart.width,
      }}
    >
      {/* {props.options.title.verticalAlign === "top" ? (
        <Typography
          sx={{
            fontSize: props.options.title.style.fontSize,
            width: "100%",
            textAlign: props.options.title.align,
            color: props.options.title.style.color,
          }}
        >
          {props.options.title.text}
        </Typography>
      ) : (
        ""
      )} */}

      <Card data-testid="ticket-list-table">
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Typography variant="h2" p={2}>
            Table View
          </Typography>
          <Stack justifyContent="center" alignItems="end" m={2}>
            <CSVLink
              headers={headers}
              data={sortedRows}
              filename={`NB Monarch Query Builder Export`}
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained">DownLoad CSV</Button>
            </CSVLink>
          </Stack>
        </Stack>
        <Divider />
        <TableContainer sx={{ maxHeight: "75vh" }}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: props.options.headers.style.headerColor,
                }}
              >
                {props.options.data.rows[0]?.name && <TableCell></TableCell>}
                {props.options.data.columns.map(
                  (column: any, index: number) => {
                    return (
                      <TableCell
                        key={index}
                        sx={{
                          textAlign: props.options.headers.headerAlign,
                          color: props.options.headers.style.color,
                          fontSize: props.options.headers.style.fontSize,
                          overflowWrap: "anywhere",
                        }}
                      >
                        {column.headerText}

                        <Box>
                          {column.sortable ? (
                            <IconButton
                              size="small"
                              sx={{
                                color: props.options.headers.style.iconColor,
                              }}
                              onClick={() => handlSortClick(column.name)}
                            >
                              <FilterListTwoToneIcon fontSize="small" />
                              {sortedColumn === column.name ? (
                                sortAscending ? (
                                  <ArrowDropDownIcon fontSize="small" />
                                ) : (
                                  <ArrowDropUpIcon fontSize="small" />
                                )
                              ) : (
                                ""
                              )}
                            </IconButton>
                          ) : (
                            ""
                          )}
                          {column.filterable ? (
                            <IconButton
                              sx={{
                                color: props.options.headers.style.iconColor,
                                margin: "0px 3px",
                              }}
                              data-testid="filter-button"
                              className={
                                filters
                                  .map((ele: any) => {
                                    return ele.attributeName;
                                  })
                                  .includes("_id") &&
                                filters.find((ele: any) => {
                                  return ele.attributeName === "_id";
                                }).value !== ""
                                  ? classes.iconFilterButtonBg
                                  : classes.iconFilterButtonNonBg
                              }
                              color="inherit"
                              size="small"
                              onClick={(event) => {
                                handleFiltering(
                                  event.currentTarget,
                                  column.name,
                                  column.name
                                );
                              }}
                            >
                              <FilterAltTwoToneIcon />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </Box>
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                backgroundColor: props.options.rows.rowTitleColor,
              }}
            >
              {sortedRows.map((row: any, index: number) => {
                return (
                  <TableRow key={index}>
                    {row?.name && (
                      <TableCell
                        sx={{
                          color: props.options.rows.rowTitleFontColor,
                          fontSize: props.options.rows.rowTitleFontSize,
                          textAlign: props.options.rows.rowTitleAlign,
                        }}
                      >
                        {row.name}
                      </TableCell>
                    )}
                    {props.options.data.columns.map(
                      (column: any, index: number) => (
                        <TableCell
                          key={index}
                          sx={{
                            textAlign: props.options.cells.cellsAlign,
                            backgroundColor:
                              props.options.cells.style.cellColor,
                            color: props.options.cells.style.color,
                            fontSize: props.options.cells.style.fontSize,
                          }}
                        >
                          {row[column.name]}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      {/* {props.options.title.verticalAlign === "bottom" ? (
        <Typography
          sx={{
            fontSize: props.options.title.style.fontSize,
            width: "100%",
            textAlign: props.options.title.align,
            color: props.options.title.style.color,
          }}
        >
          {props.options.title.text}
        </Typography>
      ) : (
        ""
      )} */}
      <Popover
        anchorEl={anchorEl_filter}
        open={openFilter}
        onClose={() => {
          setAnchorEl_filter(null);
        }}
      >
        <Card>
          <CardContent>
            <TextField
              id="outlined-basic"
              label={currentFilterColumn}
              variant="outlined"
              value={
                filters.find((ele: any) => {
                  return ele.attributeName === currentFilterColumnAttribute;
                }) !== undefined
                  ? filters.find((ele: any) => {
                      return ele.attributeName === currentFilterColumnAttribute;
                    }).value
                  : ""
              }
              onChange={(event) => {
                setFilterValue(currentFilterColumn, event.target.value);
              }}
            />
          </CardContent>
        </Card>
      </Popover>
    </Box>
  );
};

export default CustomTable;
