import axios from "../../config/axiosConfigArch";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  loadLevelTypes,
  loadLevelTypesSuceess,
  loadLevelTypesError,
} from "./LevelTypesSlice";

const getLevelTypesData = async () => {
  let levels: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`leveltypes?pageSize=100&page=${page++}`);
    levels = levels.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);

  return levels;
};

function* listLevelTypesData(): any {
  try {
    const data = yield call(() =>
      getLevelTypesData().then((res) => {
        return res;
      })
    );

    yield put({
      type: loadLevelTypesSuceess.toString(),
      payload: data,
    });
  } catch (error) {
    yield put({
      type: loadLevelTypesError.toString(),
      payload: error,
    });
  }
}

export function* LevelTypesSaga() {
  yield takeLatest(loadLevelTypes.toString(), listLevelTypesData);
}
