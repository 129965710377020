import { combineReducers } from "redux";
import { AlertReducer } from "./Alerts/AlertSlice";
import { SeedReducer } from "./Seed/SeedSlice";
import { UsersReducer } from "./UserPreference/UsersSlice";
import { VisualsReducer } from "./Visuals/VisualsSlice";
import { AliasReducer } from "./Alias/AliasSlice";
import { AssetsReducer } from "./Assets/AssetsSlice";
import { KPIReducer } from "./KPIs/KPISlice";
import { QueryReducer } from "./Query/QuerySlice";
import { SensorsReducer } from "./Sensors/SensorsSlice";
import { MachinesReducer } from "./Machines/MachinesSlice";
import { DevicesReducer } from "./Devices/DevicesSlice";
import { PlantsReducer } from "./Plants/PlantsSlice";
import { ArchUsersReducer } from "./Users/ARCHUsersSlice";
import { LevelTypesReducer } from "./LevelTypes/LevelTypesSlice";
import { AssetTypesReducer } from "./AssetTypes/AssetTypesSlice";
import { DashboardsReducer } from "./Dashboards/DashboardsSlice";
import { useCaseReducer } from "./UseCase/UseCaseSlice";
import { OEMsReducer } from "./OEMs/OEMSlice";
import { ModelReducer } from "./Models/ModelSlice";

const rootReducer = combineReducers({
  assetsList: AssetsReducer,
  Alert: AlertReducer,
  seedList: SeedReducer,
  visualsList: VisualsReducer,
  usersList: UsersReducer,
  aliasList: AliasReducer,
  KPIList: KPIReducer,
  plantsList: PlantsReducer,
  devicesList: DevicesReducer,
  machinesList: MachinesReducer,
  sensorsList: SensorsReducer,
  queryList: QueryReducer,
  levelTypeslist: LevelTypesReducer,
  assetTypesList: AssetTypesReducer,
  dashboardsList: DashboardsReducer,
  useCaseList: useCaseReducer,
  archUsersList: ArchUsersReducer,
  OEMList: OEMsReducer,
  modelList: ModelReducer
});

export default rootReducer;
