import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useCubeQuery } from "@cubejs-client/react";
import { CircularProgress, Box } from "@mui/material";
import Visual from "./Visual";
import CustomKPICard from "./CustomKPICard";
import CustomTable from "./CustomTable";
import moment from "moment";
import { ResultSet } from "@cubejs-client/core";
const dateFormatter = (item: any) => {
  if (moment(item).isValid()) {
    return moment(item).format("M/DD hh:mmA");
  } else {
    return item;
  }
};

const Spinner = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "90%",
    }}
  >
    <CircularProgress />
  </Box>
);

const TypeToChartComponent: any = {
  line: ({ resultSet, selectedVisual, pivotConfig }: any) => {
    let range: any = {};
    let categories = new Set();
    if (resultSet) {
      const dimensionValue =
        resultSet.loadResponses[0].query.dimensions === undefined ||
        resultSet.loadResponses[0].query.dimensions[0] === undefined
          ? " . "
          : resultSet.loadResponses[0].query.dimensions[0];

      const measureValue =
        resultSet.loadResponses[0].query.measures === undefined ||
        resultSet.loadResponses[0].query.measures[0] === undefined
          ? " . "
          : resultSet.loadResponses[0].query.measures[0];

      resultSet.tablePivot(pivotConfig).forEach((item: any) => {
        categories.add(
          dateFormatter(
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.timestamp.${
                resultSet.loadResponses[0].query.timeDimensions[0]?.granularity
              }`
            ]
          )
        );
        if (
          !range[
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ]
          ]
        ) {
          range[
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ]
          ] = {
            name: item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ],
            data: [],
          };
        }
        range[
          item[
            `${dimensionValue?.substr(
              0,
              dimensionValue?.indexOf(".")
            )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
          ]
        ].data.push(
          parseFloat(
            item[
              `${measureValue.substr(
                0,
                measureValue.indexOf(".")
              )}.${measureValue.substr(measureValue.indexOf(".") + 1)}`
            ]
          )
        );
      });
    }

    return (
      <div className="dashboard__cell" style={{ height: "90vh" }}>
        <Visual
          categories={Array.from(categories)}
          data={Object.values(range)}
          selectedVisual={selectedVisual}
        />
      </div>
    );
  },
  table: ({ selectedVisual, resultSet, pivotConfig }: any) => {
    const pivot = {
      x: ["FiveMinAgg.timestamp.minute"],
      y: ["measures"],
      fillMissingDates: false,
      joinDateRange: false,
    };

    let columnData: any = {};
    let rows: any = [];

    if (resultSet) {
      resultSet.tablePivot(pivotConfig).forEach((item: any) => {
        columnData = item;
        rows.push(item);
      });
    }

    // Columns
    let columnsKey: any = [];
    Object.keys(columnData).forEach((key) => {
      columnsKey.push(key);
    });

    const columns = columnsKey.map((data: any) => {
      return {
        headerText: data,
        name: data,
        sortable: true,
        filterable: true,
      };
    });

    //Table Data
    const tableData = {
      columns,
      rows,
    };

    const getObjectFromElement = (obj: any) => {
      if (obj.hasOwnProperty("subproperties")) {
        const subPropertyArray = obj["subproperties"].map((ele: any) => {
          return getObjectFromElement(ele);
        });
        const groupedObj = Object.assign({}, ...subPropertyArray);
        const finalPropObject = { [obj["property"]]: groupedObj };
        return finalPropObject;
      } else {
        const finalPropObject = { [obj["property"]]: obj["value"] };
        return finalPropObject;
      }
    };

    const temp = selectedVisual.visual_config.map((ele: any) => {
      return getObjectFromElement(ele);
    });

    const visualConfig = Object.assign({}, ...temp);
    visualConfig.data = tableData;
    return (
      <div
        className="dashboard__cell"
        style={{
          height: "90vh",
          padding: "5px",
        }}
      >
        <CustomTable options={visualConfig} />
      </div>
    );
  },

  column: ({ resultSet, selectedVisual, pivotConfig }: any) => {
    let range: any = {};
    let categories = new Set();

    if (resultSet) {
      const dimensionValue =
        resultSet.loadResponses[0].query.dimensions === undefined ||
        resultSet.loadResponses[0].query.dimensions[0] === undefined
          ? " . "
          : resultSet.loadResponses[0].query.dimensions[0];
      const measureValue =
        resultSet.loadResponses[0].query.measures === undefined ||
        resultSet.loadResponses[0].query.measures[0] === undefined
          ? " . "
          : resultSet.loadResponses[0].query.measures[0];
      resultSet.tablePivot(pivotConfig).forEach((item: any) => {
        categories.add(
          dateFormatter(
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.timestamp.${
                resultSet.loadResponses[0].query.timeDimensions[0]?.granularity
              }`
            ]
          )
        );
        if (
          !range[
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ]
          ]
        ) {
          range[
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ]
          ] = {
            name: item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ],
            data: [],
          };
        }
        range[
          item[
            `${dimensionValue?.substr(
              0,
              dimensionValue?.indexOf(".")
            )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
          ]
        ].data.push(
          parseFloat(
            item[
              `${measureValue.substr(
                0,
                measureValue.indexOf(".")
              )}.${measureValue.substr(measureValue.indexOf(".") + 1)}`
            ]
          )
        );
      });
    }

    return (
      <div className="dashboard__cell" style={{ height: "90vh" }}>
        <Visual
          categories={Array.from(categories)}
          data={Object.values(range)}
          selectedVisual={selectedVisual}
        />
      </div>
    );
  },
  bar: ({ resultSet, selectedVisual, pivotConfig }: any) => {
    let range: any = {};
    let categories = new Set();

    if (resultSet) {
      const dimensionValue =
        resultSet.loadResponses[0].query.dimensions === undefined ||
        resultSet.loadResponses[0].query.dimensions[0] === undefined
          ? " . "
          : resultSet.loadResponses[0].query.dimensions[0];
      const measureValue =
        resultSet.loadResponses[0].query.measures === undefined ||
        resultSet.loadResponses[0].query.measures[0] === undefined
          ? " . "
          : resultSet.loadResponses[0].query.measures[0];
      resultSet.tablePivot(pivotConfig).forEach((item: any) => {
        categories.add(
          dateFormatter(
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.timestamp.${
                resultSet.loadResponses[0].query.timeDimensions[0]?.granularity
              }`
            ]
          )
        );
        if (
          !range[
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ]
          ]
        ) {
          range[
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ]
          ] = {
            name: item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ],
            data: [],
          };
        }
        range[
          item[
            `${dimensionValue?.substr(
              0,
              dimensionValue?.indexOf(".")
            )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
          ]
        ].data.push(
          parseFloat(
            item[
              `${measureValue.substr(
                0,
                measureValue.indexOf(".")
              )}.${measureValue.substr(measureValue.indexOf(".") + 1)}`
            ]
          )
        );
      });
    }

    return (
      <div className="dashboard__cell" style={{ height: "90vh" }}>
        <Visual
          categories={Array.from(categories)}
          data={Object.values(range)}
          selectedVisual={selectedVisual}
        />
      </div>
    );
  },
  stackedBar: ({ resultSet, selectedVisual, pivotConfig }: any) => {
    let range: any = {};
    let categories = new Set();

    if (resultSet) {
      const dimensionValue =
        resultSet.loadResponses[0].query.dimensions === undefined ||
        resultSet.loadResponses[0].query.dimensions[0] === undefined
          ? " . "
          : resultSet.loadResponses[0].query.dimensions[0];
      const measureValue =
        resultSet.loadResponses[0].query.measures === undefined ||
        resultSet.loadResponses[0].query.measures[0] === undefined
          ? " . "
          : resultSet.loadResponses[0].query.measures[0];
      resultSet.tablePivot(pivotConfig).forEach((item: any) => {
        categories.add(
          dateFormatter(
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.timestamp.${
                resultSet.loadResponses[0].query.timeDimensions[0]?.granularity
              }`
            ]
          )
        );
        if (
          !range[
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ]
          ]
        ) {
          range[
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ]
          ] = {
            name: item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ],
            data: [],
          };
        }
        range[
          item[
            `${dimensionValue?.substr(
              0,
              dimensionValue?.indexOf(".")
            )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
          ]
        ].data.push(
          parseFloat(
            item[
              `${measureValue.substr(
                0,
                measureValue.indexOf(".")
              )}.${measureValue.substr(measureValue.indexOf(".") + 1)}`
            ]
          )
        );
      });
    }

    return (
      <div className="dashboard__cell" style={{ height: "90vh" }}>
        <Visual
          categories={Array.from(categories)}
          data={Object.values(range)}
          selectedVisual={selectedVisual}
        />
      </div>
    );
  },
  stackedColumn: ({ resultSet, selectedVisual, pivotConfig }: any) => {
    let range: any = {};
    let categories = new Set();
    if (resultSet) {
      const dimensionValue =
        resultSet.loadResponses[0].query.dimensions === undefined ||
        resultSet.loadResponses[0].query.dimensions[0] === undefined
          ? " . "
          : resultSet.loadResponses[0].query.dimensions[0];
      const measureValue =
        resultSet.loadResponses[0].query.measures === undefined ||
        resultSet.loadResponses[0].query.measures[0] === undefined
          ? " . "
          : resultSet.loadResponses[0].query.measures[0];
      resultSet.tablePivot(pivotConfig).forEach((item: any) => {
        categories.add(
          dateFormatter(
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.timestamp.${
                resultSet.loadResponses[0].query.timeDimensions[0]?.granularity
              }`
            ]
          )
        );
        if (
          !range[
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ]
          ]
        ) {
          range[
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ]
          ] = {
            name: item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ],
            data: [],
          };
        }
        range[
          item[
            `${dimensionValue?.substr(
              0,
              dimensionValue?.indexOf(".")
            )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
          ]
        ].data.push(
          parseFloat(
            item[
              `${measureValue.substr(
                0,
                measureValue.indexOf(".")
              )}.${measureValue.substr(measureValue.indexOf(".") + 1)}`
            ]
          )
        );
      });
    }

    return (
      <div className="dashboard__cell" style={{ height: "90vh" }}>
        <Visual
          categories={Array.from(categories)}
          data={Object.values(range)}
          selectedVisual={selectedVisual}
        />
      </div>
    );
  },
  area: ({ resultSet, selectedVisual, pivotConfig }: any) => {
    let range: any = {};
    let categories = new Set();
    if (resultSet) {
      const dimensionValue =
        resultSet.loadResponses[0].query.dimensions === undefined ||
        resultSet.loadResponses[0].query.dimensions[0] === undefined
          ? " . "
          : resultSet.loadResponses[0].query.dimensions[0];

      const measureValue =
        resultSet.loadResponses[0].query.measures === undefined ||
        resultSet.loadResponses[0].query.measures[0] === undefined
          ? " . "
          : resultSet.loadResponses[0].query.measures[0];

      resultSet.tablePivot(pivotConfig).forEach((item: any) => {
        categories.add(
          dateFormatter(
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.timestamp.${
                resultSet.loadResponses[0].query.timeDimensions[0]?.granularity
              }`
            ]
          )
        );
        if (
          !range[
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ]
          ]
        ) {
          range[
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ]
          ] = {
            name: item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ],
            data: [],
          };
        }
        range[
          item[
            `${dimensionValue?.substr(
              0,
              dimensionValue?.indexOf(".")
            )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
          ]
        ].data.push(
          parseFloat(
            item[
              `${measureValue.substr(
                0,
                measureValue.indexOf(".")
              )}.${measureValue.substr(measureValue.indexOf(".") + 1)}`
            ]
          )
        );
      });
    }

    return (
      <div className="dashboard__cell" style={{ height: "90vh" }}>
        <Visual
          categories={Array.from(categories)}
          data={Object.values(range)}
          selectedVisual={selectedVisual}
        />
      </div>
    );
  },
  kpi: ({ resultSet, selectedVisual, pivotConfig }: any) => {
    let range: any = {};
    let categories = new Set();
    if (resultSet) {
      const dimensionValue =
        resultSet.loadResponses[0].query.dimensions === undefined ||
        resultSet.loadResponses[0].query.dimensions[0] === undefined
          ? " . "
          : resultSet.loadResponses[0].query.dimensions[0];

      const measureValue =
        resultSet.loadResponses[0].query.measures === undefined ||
        resultSet.loadResponses[0].query.measures[0] === undefined
          ? " . "
          : resultSet.loadResponses[0].query.measures[0];

      resultSet.tablePivot(pivotConfig).forEach((item: any) => {
        categories.add(
          dateFormatter(
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.timestamp.${
                resultSet.loadResponses[0].query.timeDimensions[0]?.granularity
              }`
            ]
          )
        );
        if (
          !range[
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ]
          ]
        ) {
          range[
            item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ]
          ] = {
            name: item[
              `${dimensionValue?.substr(
                0,
                dimensionValue?.indexOf(".")
              )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
            ],
            data: [],
          };
        }
        range[
          item[
            `${dimensionValue?.substr(
              0,
              dimensionValue?.indexOf(".")
            )}.${dimensionValue?.substr(dimensionValue?.indexOf(".") + 1)}`
          ]
        ].data.push(
          parseFloat(
            item[
              `${measureValue.substr(
                0,
                measureValue.indexOf(".")
              )}.${measureValue.substr(measureValue.indexOf(".") + 1)}`
            ]
          )
        );
      });
    }
    const getObjectFromElement = (obj: any) => {
      if (obj.hasOwnProperty("subproperties")) {
        const subPropertyArray = obj["subproperties"].map((ele: any) => {
          return getObjectFromElement(ele);
        });
        const groupedObj = Object.assign({}, ...subPropertyArray);
        const finalPropObject = { [obj["property"]]: groupedObj };
        return finalPropObject;
      } else {
        const finalPropObject = { [obj["property"]]: obj["value"] };
        return finalPropObject;
      }
    };

    const temp = selectedVisual.visual_config.map((ele: any) => {
      return getObjectFromElement(ele);
    });

    const visualConfig = Object.assign({}, ...temp);
    // visualConfig.data = tableData;

    return (
      <div className="dashboard__cell" style={{ height: "90vh" }}>
        <CustomKPICard
          options={visualConfig}
          value={range[Object.keys(range)[0]].data[0]}
          header={Object.keys(range)[0]}
        />
      </div>
    );
  },
};

const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const renderChart =
  (Component: any) =>
  ({ resultSet, error, selectedVisual, pivotConfig }: any) => {
    return (
      (resultSet && (
        <Component
          resultSet={resultSet}
          selectedVisual={selectedVisual}
          pivotConfig={pivotConfig}
        />
      )) ||
      (error && error.toString()) || <Spinner />
    );
  };

const ChartRenderer = ({ selectedVisual, query, pivotConfig }: any) => {
  // const { query, chartType } = vizState;
  const chartType = selectedVisual.visual_type;
  const component = TypeToMemoChartComponent[chartType];
  const renderProps = useCubeQuery(query);

  return (
    component &&
    renderChart(component)({
      selectedVisual: selectedVisual,
      pivotConfig: pivotConfig,
      ...renderProps,
    })
  );
};

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
  query: PropTypes.object,
  cubejsApi: PropTypes.object,
  selectedVisual: PropTypes.object,
  pivotConfig: PropTypes.object,
};

ChartRenderer.defaultProps = {
  vizState: {},
  query: {},
  selectedVisual: {},
  chartHeight: 200,
  cubejsApi: null,
  pivotConfig: {},
};
export default ChartRenderer;
